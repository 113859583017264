import React from 'react'
import styles from './FormButton.module.scss'
import PropTypes from 'prop-types'
import Loading from 'components/Loading'
const FormButton = ({ type, disabled, children, onClick, loading, width }) => (
  <button
    className={styles.button}
    onClick={onClick}
    type={type}
    disabled={disabled}
    style={{
      maxWidth: width || '100%'
    }}
  >
    {/*additional div for safari 10 button flex bug fix*/}
    <div className={styles.children_container}>
      {loading ? <Loading type="dots" swapable /> : children}
    </div>
  </button>
)

export default FormButton

FormButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  width: PropTypes.string
}
