import React from 'react'
import { ReactComponent as LogoSvg } from './AudiodrillzLogo.svg'
import PropTypes from 'prop-types'
import styles from './Logo.module.scss'

function Logo({ size }) {
  return (
    <div className={styles.logo_container} style={{ width: size || '100%' }}>
      <LogoSvg />
    </div>
  )
}

Logo.propTypes = {
  size: PropTypes.string
}

export default Logo
