import React, { useEffect } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import Logo from 'components/Logo'
import Cookies from 'js-cookie'
import { animated, useSpring } from 'react-spring'
import styles from './Welcome.module.scss'
import { ReactComponent as Button } from './Button.svg'
import { connect } from 'react-redux'
import { isLoggedIn } from 'reducers/auth'
import { PropTypes } from 'prop-types'
import MainBg from './MainBg.jpg'

function Welcome({ isLoggedIn }) {
  const propsReveal = useSpring({ opacity: 1, from: { opacity: 0 } })
  const history = useHistory()

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/games')
    }

    const returnedUser = Cookies.get('returnedUser')

    if (!returnedUser) {
      Cookies.set('returnedUser', 'true')
    } else {
      history.push('/games')
    }
  }, []) // eslint-disable-line

  return (
    <animated.div style={propsReveal}>
      <div className={styles.container}>
        <div className={styles.left}>
          <img className={styles.bgImg} src={MainBg} alt="background" />
        </div>
        <div className={styles.right}>
          <div className={styles.logo_box}>
            <Logo />
          </div>
          <p>
            Hello and welcome to Audiodrillz - an ear training web application
            for music producers, audio engineers, sound designers, musicians,
            DJs and anyone who wants to improve their listening skills. By doing
            some gamelike exercises every day, in a short time you will acquire
            an ability to recognize different frequencies and common audio
            effects settings easily, which will drastically boost your mixing
            and sound production capabilities.
          </p>
          <div className={styles.link_box}>
            <Link to="/games" className={styles.try}>
              <Button />
            </Link>
          </div>
        </div>
      </div>

      {/* <section className={styles.content_section}>
        <h1 className={styles.section_title}>EQ EAR TRAINING</h1>
        <p className={styles.section_text}>bla bla bla</p>
        <a href="">Start eq ear training</a>
      </section>

      <section className={styles.content_section}>
        <h1 className={styles.section_title}>COMPRESSION EAR TRAINING</h1>
        <p className={styles.section_text}>bla bla bla</p>
        <a href="">Start compression ear training</a>
      </section>

      <section className={styles.content_section}>
        <h1 className={styles.section_title}>DELAY EAR TRAINNG</h1>
        <p className={styles.section_text}>bla bla bla</p>
        <a href="">Start delay effects ear training</a>
      </section> */}
    </animated.div>
  )
}

Welcome.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
}

export default connect((state) => ({ isLoggedIn: isLoggedIn(state) }))(Welcome)
