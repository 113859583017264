import React from 'react'
import Game from 'modules/Game'
import DelayTime from 'modules/DelayTime'
import * as gameTypes from 'constants/gameTypes'

const GameDelayTime = () => {
  return (
    <Game gameType={gameTypes.DELAY_TIME} withBubble={false}>
      {({ next, points }) => (
        <DelayTime next={next} points={points} key={gameTypes.DELAY_TIME} />
      )}
    </Game>
  )
}

export default GameDelayTime
