import React, { PureComponent } from 'react'
// import * as R from 'ramda'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Howl, Howler } from 'howler'
import { getCurrentSlice } from 'reducers/sprites'

function withHowler(TunaComponent) {
  class HowlerTrack extends PureComponent {
    constructor(props) {
      super(props)

      // this.getNextSlice = HowlerTrack.sliceIterator(this.props.sprite)

      this.state = {
        howl: null,
      }
    }

    componentDidMount() {
      this.initHowler()
    }

    static propTypes = {
      src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      sprite: PropTypes.object,
      playing: PropTypes.bool,
      mute: PropTypes.bool,
      stop: PropTypes.bool,
      loop: PropTypes.bool,
      volume: PropTypes.number,
      html5: PropTypes.bool,
      slice: PropTypes.string,
      preload: PropTypes.bool,
    }

    static defaultProps = {
      mute: false,
      preload: true,
      loop: true,
      volume: 1.0,
      html5: false,
    }

    // Currently this is being managed in sprites reducer

    // static *sliceIterator(sprite) {
    //   const slices = R.keys(sprite)
    //   let idx = 0

    //   while (true) {
    //     yield slices[idx]

    //     idx++
    //     if (idx >= slices.length) {
    //       idx = 0
    //     }
    //   }
    // }

    componentDidUpdate(prevProps, prevState) {
      const { src } = this.props

      //play howler after init
      if (prevState !== this.state && this.state.howl && this.props.playing) {
        this.play()
      }

      if (src !== prevProps.src) {
        this.initHowler()
      } else {
        this.toggleHowler(this.props, prevProps)
      }
    }

    componentWillUnmount() {
      this.destroyHowler()
    }

    initHowler() {
      const props = this.props

      this.destroyHowler()

      if (typeof Howl !== 'undefined') {
        // Check if window is available
        const howl = new Howl({
          src: props.src,
          sprite: props.sprite,
          mute: props.mute,
          loop: props.loop,
          preload: props.preload,
          volume: props.volume,
          html5: props.html5,
        })

        this.setState({ howl })
      }
    }

    destroyHowler() {
      const { howl } = this.state

      if (howl) {
        howl.off() // Remove event listener
        howl.stop()
        howl.unload() // Remove sound from pool

        this.setState({ howl: null })
      }
    }

    toggleHowler(newProps, prevProps) {
      if (newProps.playing && !prevProps.playing) {
        this.play()
      }

      if (!newProps.playing && prevProps.playing) {
        this.stop()
      }

      if (newProps.mute !== prevProps.mute) {
        this.state.howl.mute(newProps.mute)
      }
    }

    play() {
      const { howl } = this.state
      const { slice } = this.props

      const playing = howl.playing()

      if (!playing) {
        // Automatically load if we're trying to play
        // and the howl is not loaded
        if (howl.state() === 'unloaded') {
          howl.load()
        }

        howl.play(slice)
      }
    }

    stop() {
      this.state.howl.stop()
    }

    render() {
      return (
        <TunaComponent howl={this.state.howl} howler={Howler} {...this.props} />
      )
    }
  }
  const mapStateToProps = (state) => ({ slice: getCurrentSlice(state) })

  return connect(mapStateToProps)(HowlerTrack)
}

export default withHowler
