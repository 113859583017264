import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Tuna from 'tunajs'

import withHowler from 'components/HowlerTuna/'

class HowlerTunaCompressor extends PureComponent {
  static propTypes = {
    attack: PropTypes.number,
    release: PropTypes.number,
    ratio: PropTypes.number,
    threshold: PropTypes.number,
    bypass: PropTypes.bool,
    howl: PropTypes.object,
    howler: PropTypes.object.isRequired
  }

  static defaultProps = {
    attack: 5,
    release: 200,
    ratio: 20,
    threshold: -20,
    bypass: false
  }

  componentDidUpdate(prevProps) {
    const { bypass, howl, attack, release, ratio } = this.props

    if (howl && prevProps.howl !== this.props.howl) {
      this.initCompressor()
    }

    if (
      attack !== prevProps.attack ||
      release !== prevProps.release ||
      ratio !== prevProps.ratio
    ) {
      this.updateCompressor(attack, release, ratio)
    }

    if (bypass !== prevProps.bypass) {
      this.updateBypass(bypass)
    }
  }

  initCompressor() {
    const { attack, release, ratio } = this.props

    const ctx = this.props.howler.ctx
    const tuna = new Tuna(ctx)
    const howlNode = this.props.howl._sounds[0]._node

    this.compressor = new tuna.Compressor({
      threshold: -20, //-100 to 0
      makeupGain: 1, //0 and up (in decibels)
      attack, //0 to 1000
      release, //0 to 3000
      ratio, //1 to 20
      knee: 6, //0 to 40
      automakeup: true, //true/false
      bypass: 0
    })

    howlNode.disconnect(this.props.howler.masterGain)
    howlNode.connect(this.compressor)
    this.compressor.connect(this.props.howler.masterGain)
  }

  updateCompressor(attack, release, ratio) {
    this.compressor.attack = attack
    this.compressor.release = release
    this.compressor.ratio = ratio
  }

  updateBypass(bypass) {
    this.compressor.bypass = bypass
  }

  render() {
    return null
  }
}

export default withHowler(HowlerTunaCompressor)
