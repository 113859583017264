import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Tuna from 'tunajs'

import withHowler from 'components/HowlerTuna/'

class HowlerTunaDb extends PureComponent {
  static propTypes = {
    gain: PropTypes.number,
    bypass: PropTypes.bool,
    howl: PropTypes.object,
    howler: PropTypes.object.isRequired
  }

  static defaultProps = {
    volume: 0.5,
    bypass: false
  }

  componentDidUpdate(prevProps) {
    const { bypass, howl, gain } = this.props

    if (howl && prevProps.howl !== this.props.howl) {
      this.initDb(gain)
    }

    if (gain !== prevProps.gain) {
      this.updateVolume(gain)
    }

    if (bypass !== prevProps.bypass) {
      this.updateBypass(bypass)
    }
  }

  initDb(gain) {
    const ctx = this.props.howler.ctx
    const tuna = new Tuna(ctx)
    const howlNode = this.props.howl._sounds[0]._node

    this.gain = new tuna.Gain({
      gain // 0 and up
    })

    howlNode.disconnect(this.props.howler.masterGain)
    howlNode.connect(this.gain)
    this.gain.connect(this.props.howler.masterGain)
  }

  updateVolume(gain) {
    if (!Number.isFinite(gain)) {
      return
    }

    this.gain.gain = gain
  }

  updateBypass(bypass) {
    this.gain.bypass = bypass
  }

  render() {
    return null
  }
}

export default withHowler(HowlerTunaDb)
