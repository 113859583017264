import { PureComponent } from 'react'
import Tuna from 'tunajs'
import PropTypes from 'prop-types'
import withHowler from 'components/HowlerTuna/'

class TunaEq extends PureComponent {
  static propTypes = {
    //Filter props
    frequency: PropTypes.number, //20 to 22050
    Q: PropTypes.number, //0.001 to 100
    gain: PropTypes.number, //-40 to 40 (in decibels)
    filterType: PropTypes.string, //lowpass, highpass, bandpass, lowshelf, highshelf, peaking, notch, allpass
    bypass: PropTypes.bool,

    howl: PropTypes.object,
    howler: PropTypes.object.isRequired,
    filterData: PropTypes.object.isRequired
  }

  static defaultProps = {
    //Filter props
    bypass: false,
    Q: 5, //0.001 to 100
    //filter data that changes
    filterData: {
      frequency: 1000, //20 to 22050
      gain: 0, //-40 to 40 (in decibels)
      filterType: 'allpass' //lowpass, highpass, bandpass, lowshelf, highshelf, peaking, notch, allpass
    }
  }

  componentDidUpdate(prevProps) {
    const {
      filterData,
      filterData: { filterType, frequency, gain } = {},
      bypass,
      howl
    } = this.props

    if (howl && prevProps.howl !== this.props.howl) {
      this.initFilter()
    }

    if (filterData !== prevProps.filterData) {
      this.updateFilterType(filterType, frequency, gain)
    }

    if (bypass !== prevProps.bypass) {
      this.updateBypass(bypass)
    }
  }

  initFilter() {
    const {
      filterData: { filterType, frequency, gain } = {},
      bypass,
      Q
    } = this.props

    const ctx = this.props.howler.ctx
    const tuna = new Tuna(ctx)
    const howlNode = this.props.howl._sounds[0]._node

    this.filter = new tuna.Filter({
      frequency, //20 to 22050
      Q, //0.001 to 100
      gain, //-40 to 40 (in decibels)
      filterType, //lowpass, highpass, bandpass, lowshelf, highshelf, peaking, notch, allpass
      bypass
    })

    howlNode.disconnect(this.props.howler.masterGain)
    howlNode.connect(this.filter)
    this.filter.connect(this.props.howler.masterGain)
  }

  updateFilterType(filterType, frequency, gain) {
    this.filter.filterType = filterType
    this.filter.frequency = frequency
    this.filter.gain = gain
  }

  updateBypass(bypass) {
    this.filter.bypass = bypass
  }

  render() {
    return null
  }
}

export default withHowler(TunaEq)
