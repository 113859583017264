import React, { PureComponent } from 'react'
import HowlerTunaPan from './HowlerTunaPan'
import Display from 'components/Display'
import TooltipDisplayHead from 'components/TooltipDisplayHead'
import ControlPanel from 'components/ControlPanel'
import { connect } from 'react-redux'
import { getCurrentSprite } from 'reducers/sprites'
import { getCurrentLevel } from 'reducers/game'
import { answer } from 'actions'
import PropTypes from 'prop-types'
import { getCurrentQuestionValue } from 'reducers/questions'
import styles from './Pan.module.scss'
import Grid from './PanGrid'

import { isFinite } from 'lodash'

class Pan extends PureComponent {
  constructor(props) {
    super(props)

    this.onHeadPositionChange = this.onHeadPositionChange.bind(this)
    this.handleAnswer = this.handleAnswer.bind(this)

    this.state = {
      panPosition: 0
    }
  }

  static propTypes = {
    sprite: PropTypes.object,
    currentQuestion: PropTypes.number,
    src: PropTypes.string.isRequired,
    playing: PropTypes.bool.isRequired,
    panPosition: PropTypes.number,
    bypass: PropTypes.bool.isRequired,
    muted: PropTypes.bool.isRequired,
    level: PropTypes.number.isRequired,
    answer: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    panPositionTuna: PropTypes.number.isRequired,
    panPositionQuestion: PropTypes.number
  }

  static positionToPan(position, containerWidth) {
    const middle = containerWidth / 2
    let panPosition = 0

    //invert direction to count
    const positionLeft = middle - position
    const positionRight = position - middle

    if (position < containerWidth / 2) {
      panPosition = 0 - Math.ceil((positionLeft / (containerWidth / 100)) * 2)
    } else {
      panPosition = Math.ceil((positionRight / (containerWidth / 100)) * 2)
    }

    return panPosition
  }

  onHeadPositionChange(position, containerWidth) {
    const panPosition = Pan.positionToPan(position, containerWidth)

    this.setState({
      panPosition
    })
  }

  handleAnswer(position) {
    //don't trigger answer if track isn't playing
    if (!this.props.playing) {
      return
    }

    const { level, panPositionQuestion } = this.props
    const panPositionAnswer = this.state.panPosition

    const delta = Math.abs(panPositionQuestion - panPositionAnswer)

    const deltaPerfect = 12 - level
    const deltaGood = 18 - level

    if (delta < deltaPerfect) {
      this.props.answer('PERFECT', position)
    } else if (delta < deltaGood) {
      this.props.answer('GOOD', position)
    } else {
      this.props.answer('FAIL', position)
    }

    this.props.next()
  }

  render() {
    const { src, sprite, playing, bypass, muted, panPositionTuna } = this.props
    const { panPosition } = this.state

    return (
      <div className={styles.container}>
        <HowlerTunaPan
          src={src}
          sprite={sprite}
          playing={playing}
          panPosition={panPositionTuna}
          bypass={bypass}
          mute={muted}
        />
        <Display
          ref={this.container}
          grid={Grid}
          onHeadPositionChange={this.onHeadPositionChange}
          onHeadClick={this.handleAnswer}
        >
          <TooltipDisplayHead position={panPosition > 0 ? 'right' : 'left'}>
            {panPosition === 0
              ? panPosition
              : Math.abs(panPosition) + (panPosition > 0 ? ` Right` : ` Left`)}
          </TooltipDisplayHead>
        </Display>

        <ControlPanel bypassTextOn={'Pan on'} bypassTextOff={'Pan off'} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const panPositionQuestion = getCurrentQuestionValue(state)

  //avoiding passing non numeric values to Tuna panner
  const panPositionTuna = isFinite(panPositionQuestion)
    ? panPositionQuestion / 100
    : 0

  return {
    sprite: getCurrentSprite(state),
    level: getCurrentLevel(state),
    panPositionQuestion,
    panPositionTuna,
    ...state.player
  }
}

export default connect(mapStateToProps, { answer })(Pan)
