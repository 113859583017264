export const EQ_PEAK = 'EQ_PEAK'
export const EQ_CUT = 'EQ_CUT'
export const PAN = 'PAN'
export const DB_QUIZE = 'DB_QUIZE'
export const DB_PRO = 'DB_PRO'
export const FILTER_TYPE = 'FILTER_TYPE'
export const DELAY_TIME = 'DELAY_TIME'
export const DISTORTION = 'DISTORTION'
export const REVERB = 'REVERB'
export const COMPRESSOR_ATTACK = 'COMPRESSOR_ATTACK'
export const COMPRESSOR_RELEASE = 'COMPRESSOR_RELEASE'
export const COMPRESSOR_RATIO = 'COMPRESSOR_RATIO'
