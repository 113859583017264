import React, { PureComponent } from 'react'
import HowlerTunaEq from './HowlerTunaEq'
import Display from 'components/Display'
import TooltipDisplayHead from 'components/TooltipDisplayHead'
import ControlPanel from 'components/ControlPanel'
import styles from './Eq.module.scss'
import { connect } from 'react-redux'
import { getCurrentQuestionValue } from 'reducers/questions'
import { getCurrentSprite } from 'reducers/sprites'
import { getCurrentLevel } from 'reducers/game'
import { answer } from 'actions'
import { EQ_PEAK, EQ_CUT } from 'constants/gameTypes'
import PropTypes from 'prop-types'
import { ReactComponent as Grid } from './EqGrid.svg'

export class Eq extends PureComponent {
  constructor(props) {
    super(props)

    this.onHeadPositionChange = this.onHeadPositionChange.bind(this)
    this.handleAnswer = this.handleAnswer.bind(this)
    this.setInitialPosition = this.setInitialPosition.bind(this)

    this.state = {
      freq: 1000,
      position: 1
    }
  }

  static propTypes = {
    gameType: PropTypes.string,
    sprite: PropTypes.object,
    currentQuestion: PropTypes.number,
    frequency: PropTypes.number,
    gain: PropTypes.number,
    filterType: PropTypes.oneOf([
      'lowpass',
      'highpass',
      'bandpass',
      'lowshelf',
      'highshelf',
      'peaking',
      'notch',
      'allpass'
    ]),
    src: PropTypes.string.isRequired,
    playing: PropTypes.bool.isRequired,
    bypass: PropTypes.bool.isRequired,
    muted: PropTypes.bool.isRequired,
    level: PropTypes.number,
    answer: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired
  }

  static freqToPosition(freq, width, startFreq, endFreq) {
    const logStartFreq = Math.log10(startFreq)
    const logEndFreq = Math.log10(endFreq)
    const logFreq = Math.log10(freq)

    const position =
      (width * (logFreq - logStartFreq)) / (logEndFreq - logStartFreq)

    return Math.round(position)
  }

  static positionToFreq(position, width, startFreq, endFreq) {
    const logStartFreq = Math.log10(startFreq)
    const logEndFreq = Math.log10(endFreq)

    const freq = Math.pow(
      10,
      ((logEndFreq - logStartFreq) / width) * position + logStartFreq
    )

    return Math.round(freq)
  }

  setInitialPosition(containerWidth) {
    const position = Eq.freqToPosition(
      this.state.freq,
      containerWidth,
      30,
      20000
    )

    this.setState({
      position
    })
  }

  handleAnswer(position) {
    //don't trigger answer if track isn't playing
    if (!this.props.playing) {
      return
    }

    const currentFreq = this.props.frequency
    const answerFreq = this.state.freq
    const { level } = this.props

    const delta =
      Math.abs(Math.log10(currentFreq) - Math.log10(answerFreq)) * 100
    const deltaPerfect = 12 - level
    const deltaGood = 18 - level

    if (delta < deltaPerfect) {
      this.props.answer('PERFECT', position)
    } else if (delta < deltaGood) {
      this.props.answer('GOOD', position)
    } else {
      this.props.answer('FAIL', position)
    }

    this.props.next()
  }

  onHeadPositionChange(needlePosition, containerWidth) {
    const freq = Eq.positionToFreq(needlePosition, containerWidth, 30, 20000)

    this.setState({
      freq
    })
  }

  render() {
    const {
      src,
      sprite,
      playing,
      frequency,
      bypass,
      muted,
      filterType,
      gain,
      level,
      gameType
    } = this.props

    const perLevelGain = 8 - (level - 1)
    const safePerLevelGain = perLevelGain >= 4 ? perLevelGain : 4
    const perLevelGainByType =
      gameType === EQ_CUT ? 0 - safePerLevelGain : safePerLevelGain

    return (
      <div className={styles.container}>
        <HowlerTunaEq
          src={src}
          sprite={sprite}
          playing={playing}
          frequency={frequency}
          filterType={filterType}
          gain={
            gameType === EQ_PEAK || gameType === EQ_CUT
              ? perLevelGainByType
              : gain
          }
          bypass={bypass}
          mute={muted}
        />
        <div className={styles.current}>
          <p>Q: {4}</p>
          <p>
            Gain: {perLevelGainByType > 0 && '+'}
            {perLevelGainByType}Db
          </p>
        </div>
        <Display
          ref={this.container}
          position={this.state.position}
          setInitialPosition={this.setInitialPosition}
          grid={Grid}
          onHeadPositionChange={this.onHeadPositionChange}
          onHeadClick={this.handleAnswer}
        >
          <TooltipDisplayHead
            position={this.state.freq > 3000 ? 'right' : 'left'}
          >
            {this.state.freq} Hz
          </TooltipDisplayHead>
        </Display>

        <ControlPanel bypassTextOn={'Eq on'} bypassTextOff={'Eq off'} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    level: getCurrentLevel(state),
    sprite: getCurrentSprite(state),
    frequency: getCurrentQuestionValue(state),
    ...state.player
  }
}

export default connect(mapStateToProps, { answer })(Eq)
