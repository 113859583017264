import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Tuna from 'tunajs'

import withHowler from 'components/HowlerTuna/'

class HowlerTunaDelay extends PureComponent {
  static propTypes = {
    delayTime: PropTypes.number,
    bypass: PropTypes.bool,
    howl: PropTypes.object,
    howler: PropTypes.object.isRequired
  }

  static defaultProps = {
    delayTime: 0,
    bypass: false
  }

  componentDidUpdate(prevProps) {
    const { bypass, howl, delayTime } = this.props

    if (howl && prevProps.howl !== this.props.howl) {
      this.initDelay(delayTime)
    }

    if (delayTime !== prevProps.delayTime) {
      this.updateDelay(delayTime)
    }

    if (bypass !== prevProps.bypass) {
      this.updateBypass(bypass)
    }
  }

  initDelay(delayTime) {
    const ctx = this.props.howler.ctx
    const tuna = new Tuna(ctx)
    const howlNode = this.props.howl._sounds[0]._node

    this.delay = new tuna.Delay({
      delayTime, //1 to 10000 milliseconds
      feedback: 0.45, //0 to 1+
      wetLevel: 0.5, //0 to 1+
      dryLevel: 1, //0 to 1+
      cutoff: 20000, //cutoff frequency of the built in lowpass-filter. 20 to 22050
      bypass: 0
    })

    howlNode.disconnect(this.props.howler.masterGain)
    howlNode.connect(this.delay)
    this.delay.connect(this.props.howler.masterGain)
  }

  updateDelay(delayTime) {
    this.delay.delayTime = delayTime
  }

  updateBypass(bypass) {
    this.delay.bypass = bypass
  }

  render() {
    return null
  }
}

export default withHowler(HowlerTunaDelay)
