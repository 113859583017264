import {
  REGISTRATION_REQUEST,
  REGISTRATION_FAILURE,
  REGISTRATION_SUCCESS,
  EMAIL_CONFIRMATION_REQUEST,
  EMAIL_CONFIRMATION_SUCCESS,
  EMAIL_CONFIRMATION_EXPIRED,
  EMAIL_RESEND_REQUEST,
  EMAIL_RESEND_SUCCESS,
  EMAIL_RESEND_FAILURE
} from 'constants/actionTypes'

import {
  requestEmailConfirmation,
  requestRegistration,
  requestResendEmail
} from '../api'
import { SubmissionError } from 'redux-form'
import { replace } from 'connected-react-router'
import jwt from 'jsonwebtoken'
import { errorHandler } from 'utils/errorHandler'
import get from 'lodash/get'

export const register = values => async dispatch => {
  dispatch({
    type: REGISTRATION_REQUEST
  })

  try {
    const response = await requestRegistration(values)

    const email = response.data.email

    dispatch({
      type: REGISTRATION_SUCCESS,
      payload: email
    })

    dispatch(replace('/confirmation'))
  } catch (err) {
    dispatch({ type: REGISTRATION_FAILURE })

    const errData = get(err, 'response.data')

    if (errData) {
      if (errData.email || errData.password) {
        throw new SubmissionError(errData)
      }
    }

    errorHandler(err, dispatch)
  }
}

export const confirm = emailToken => async dispatch => {
  try {
    const email = jwt.decode(emailToken).email

    dispatch({
      type: EMAIL_CONFIRMATION_REQUEST,
      payload: email
    })

    await requestEmailConfirmation(emailToken)

    dispatch({
      type: EMAIL_CONFIRMATION_SUCCESS
    })

    dispatch(replace('/login'))
    try {
      localStorage.setItem('emailConfirmed', 'confirmed')
    } catch (err) {
      //eslint-disable-next-line
      console.error(err)
    }
  } catch (err) {
    const errDataExpired = get(err, 'response.data.expired')

    if (errDataExpired) {
      return dispatch({
        type: EMAIL_CONFIRMATION_EXPIRED
      })
    }

    errorHandler(err, dispatch)
  }
}

export const resendEmail = (email, recaptchaValue) => async dispatch => {
  try {
    dispatch({ type: EMAIL_RESEND_REQUEST })

    await requestResendEmail({ email, recaptchaValue })

    dispatch({ type: EMAIL_RESEND_SUCCESS })
  } catch (err) {
    dispatch({ type: EMAIL_RESEND_FAILURE })

    const status = get(err, 'response.status')

    if (status === 421) {
      throw new SubmissionError({
        _error: 'Please check the captcha and try again'
      })
    }

    const isVerified = get(err, 'response.data.isVerified')

    if (isVerified) {
      //do not resend email if account has been already verified in another tab
      dispatch({
        type: EMAIL_CONFIRMATION_SUCCESS
      })

      dispatch(replace('/login'))
    } else {
      errorHandler(err, dispatch)
    }
  }
}
