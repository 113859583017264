import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AVATAR_UPLOAD_SUCCESS,
  LOGOUT
} from 'constants/actionTypes'

import { path } from 'ramda'

const initialState = {
  isLoggingIn: false,
  isLoggedIn: false,
  user: {},
  error: null
}

const auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_REQUEST:
      return {
        ...state,
        isLoggingIn: true
      }
    case AUTH_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        user: payload,
        error: null
      }
    case AUTH_FAILURE:
      return {
        ...initialState,
        error: payload
      }
    case LOGOUT:
      return {
        ...initialState
      }
    case AVATAR_UPLOAD_SUCCESS: {
      return {
        ...state,
        user: { ...state.user, avatar: payload }
      }
    }
    default:
      return state
  }
}

export default auth

export const isLoggedIn = state => path(['auth', 'isLoggedIn'], state)
export const isLoggingIn = state => path(['auth', 'isLoggingIn'], state)
export const getUser = state => path(['auth', 'user'], state)
