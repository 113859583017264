import React from 'react'
import Game from 'modules/Game'
import * as gameTypes from 'constants/gameTypes'
import CompressorAttack from 'modules/Compressor/Compressor'

const GameCompressor = () => {
  return (
    <Game gameType={gameTypes.COMPRESSOR_ATTACK} withBubble={false}>
      {({ next, points }) => (
        <CompressorAttack
          next={next}
          points={points}
          key={'compressor_attack'}
        />
      )}
    </Game>
  )
}

export default GameCompressor
