import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withHowler from 'components/HowlerTuna/'
import Reverb from './Tone/effect/Reverb'
import Tone from './Tone/core/Tone'
class HowlerToneReverb extends PureComponent {
  static propTypes = {
    decay: PropTypes.number,
    wet: PropTypes.number,
    bypass: PropTypes.bool,
    howl: PropTypes.object,
    howler: PropTypes.object.isRequired
  }

  static defaultProps = {
    wet: 1,
    dry: 1,
    decay: 1
  }

  async componentDidUpdate(prevProps) {
    const { bypass, howl, decay, wet } = this.props

    if (howl && prevProps.howl !== this.props.howl) {
      await this.initReverb(decay, wet)
    }

    if (decay !== prevProps.decay || wet !== prevProps.wet) {
      await this.updateReverb(decay, wet)
    }

    if (bypass !== prevProps.bypass) {
      await this.updateBypass(bypass)
    }
  }

  async initReverb(decay, wet) {
    const ctx = this.props.howler.ctx
    Tone.context = ctx

    this.reverb = new Reverb(decay)
    this.reverb.wet.value = wet

    await this.reverb.generate()

    const howlNode = this.props.howl._sounds[0]._node

    howlNode.disconnect(this.props.howler.masterGain)

    Tone.connect(howlNode, this.reverb)
    Tone.connect(this.reverb, this.props.howler.masterGain)
  }

  async updateReverb(decay, wet) {
    if (Number.isFinite(decay) && Number.isFinite(wet)) {
      this.reverb.decay = decay
      this.reverb.wet.value = wet
    }
    return await this.reverb.generate()
  }

  async updateBypass(bypass) {
    const { wet } = this.props

    if (Number.isFinite(wet)) {
      this.reverb.wet.value = bypass ? 0 : wet
      return await this.reverb.generate()
    }
  }

  render() {
    return null
  }
}

export default withHowler(HowlerToneReverb)
