import React, { PureComponent } from 'react'
import styles from './SliderDisplay.module.scss'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { connect } from 'react-redux'
import { getCurrentQuestion } from 'reducers/game'

export class SliderDisplay extends PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      value: 0
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleSpacePress)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleSpacePress)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuestion !== this.props.currentQuestion) {
      this.setState({ value: 0 })
    }
  }

  handleSpacePress = e => {
    e.preventDefault()

    if (e.key === 'Spacebar' || e.key === ' ') {
      this.handleAnswer()
    }
  }

  handleChange = value => {
    this.setState({
      value: value
    })
  }

  handleAnswer = () => {
    this.props.onAnswer(this.state.value)
  }

  renderBubble() {
    const { points, show, result, actual } = this.props

    const renderShow = points =>
      points ? styles.bubble_right : styles.bubble_wrong

    return (
      <div className={show ? renderShow(points) : styles.bubble}>
        <h1 className={styles.result}>{result}</h1>
        <p>Actual: {actual}</p>
        <p> + {points} points</p>
      </div>
    )
  }

  render() {
    const { value } = this.state

    return (
      <div className={styles.container}>
        <div className={styles.display_value}>
          {value > 0 ? `+${value} Db` : `${value} Db`}
        </div>
        {this.renderBubble()}
        <div className={styles.slider_panel}>
          <div className={styles.slider_container}>
            <Slider
              min={-20}
              max={6}
              step={1}
              tooltip={false}
              orientation="vertical"
              value={value}
              onChange={this.handleChange}
            />
            <p className={styles.slider_label}>Volume</p>
          </div>
        </div>
        <button className={styles.answer_button} onClick={this.handleAnswer}>
          Click here or press Space to answer
        </button>
      </div>
    )
  }
}

export default connect(state => ({
  ...state.bubble,
  currentQuestion: getCurrentQuestion(state)
}))(SliderDisplay)
