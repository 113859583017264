import React, { PureComponent } from 'react'
import SliderDisplay from 'components/SliderDisplay'
import ControlPanel from 'components/ControlPanel'
import HowlerDb from './HowlerTunaDb'
import { connect } from 'react-redux'
import { answer } from 'actions'
import { getCurrentSprite } from 'reducers/sprites'
import { getCurrentQuestionValue } from 'reducers/questions'
import { getCurrentLevel, getCurrentQuestion } from 'reducers/game'

import { gainToDb } from './utils'
import PropTypes from 'prop-types'

export class DbPro extends PureComponent {
  static propTypes = {
    playing: PropTypes.bool.isRequired,
    answer: PropTypes.func.isRequired,
    gain: PropTypes.number,
    next: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    sprite: PropTypes.object,
    bypass: PropTypes.bool.isRequired,
    muted: PropTypes.bool.isRequired,
  }

  onAnswer = (answer) => {
    //don't trigger answer if track isn't playing
    if (!this.props.playing) {
      return
    }

    const actual = Math.round(gainToDb(this.props.gain))

    const delta = Math.abs(answer - actual)

    //first check if actual and answer has the same sign - if don't this is  fail,
    //because listener should clearly hear if the sound become louder or quieter
    if (actual * answer < 0 || (actual && answer === 0)) {
      this.props.answer('FAIL')
    } else if (delta === 0) {
      this.props.answer('PERFECT')
    } else if (delta <= 2) {
      this.props.answer('GOOD')
    } else {
      this.props.answer('FAIL')
    }

    this.props.next()
  }

  render() {
    const { gain, src, sprite, playing, bypass, muted } = this.props
    const actual = Math.round(gainToDb(gain))
    const actualString = actual > 0 ? `+${actual} dB` : `${actual} dB`

    return (
      <div>
        <HowlerDb
          gain={gain}
          src={src}
          sprite={sprite}
          playing={playing}
          bypass={bypass}
          mute={muted}
        />
        <SliderDisplay onAnswer={this.onAnswer} actual={actualString} />
        <ControlPanel
          bypassTextOn={'Gain change on'}
          bypassTextOff={'Gain change off'}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    level: getCurrentLevel(state),
    currentQuestion: getCurrentQuestion(state),
    sprite: getCurrentSprite(state),
    gain: getCurrentQuestionValue(state),
    ...state.player,
  }
}

export default connect(mapStateToProps, { answer })(DbPro)
