export function translateToHuman(types, key) {
  if (typeof types === 'object') {
    if (key in types) {
      return types[key].replace('_', ' ')
    }
  } else if (typeof types === 'string') {
    return types.replace('_', ' ')
  } else {
    return ''
  }
}
