import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  LOGOUT
} from 'constants/actionTypes'

import { requestAuth, requestLogout } from '../api'
import { SubmissionError } from 'redux-form'
import { replace } from 'connected-react-router'
import { errorHandler } from 'utils/errorHandler'
import get from 'lodash/get'

export const auth = values => async dispatch => {
  dispatch({
    type: AUTH_REQUEST,
    payload: values.email
  })

  try {
    const response = await requestAuth(values)
    const user = response.data.user

    dispatch({
      type: AUTH_SUCCESS,
      payload: user
    })
  } catch (err) {
    dispatch({
      type: AUTH_FAILURE
    })

    const errData = get(err, 'response.data')

    if (errData) {
      if (errData.email || errData.password) {
        throw new SubmissionError(errData)
      }

      if (errData.verification) {
        return dispatch(replace('/confirmation'))
      }
    }

    errorHandler(err, dispatch)
  }
}

export const logout = () => async dispatch => {
  try {
    await requestLogout()
    dispatch({
      type: LOGOUT
    })
    dispatch(replace('/login'))
  } catch (err) {
    errorHandler(err, dispatch)
  }
}
