import * as t from 'constants/actionTypes'
import { soundsURL } from 'constants/URL'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = {
  src: `${soundsURL}default.mp3`,
  playing: false,
  bypass: false,
  muted: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE:
      return initialState
    case t.FETCH_GAME_START:
      return {
        ...state, //preserve track src between games
        playing: false,
        bypass: false,
        muted: false
      }
    case t.FETCH_TRACK_SUCCESS:
      return {
        ...state,
        src: `${soundsURL}${payload.genre}.mp3`,
        playing: true
      }
    case t.GAME_START:
      return {
        ...state, //preserve track src between games
        bypass: false,
        muted: false,
        playing: true
      }
    case t.SWITCH_BYPASS:
      return {
        ...state,
        bypass: payload
      }
    case t.SWITCH_MUTE:
      return {
        ...state,
        muted: payload
      }
    case t.ANSWER:
      return {
        ...state,
        playing: false
      }
    case t.NEXT_QUESTION:
      return {
        ...state,
        bypass: false,
        playing: true
      }

    default:
      return state
  }
}
