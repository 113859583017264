import React from 'react'
import styles from './PrivacyPolicy.module.scss'
// import PropTypes from 'prop-types'

function PrivacyPolicy() {
  return (
    <div className={styles.container}>
      <h1>Privacy Policy</h1>
      <p>Last updated: 20.11.2019</p>
      <p>
        While visiting our website, we use a third party solution such as{' '}
        <a href="https://www.google.com/analytics/">Google Analytics</a> to help
        us get insight in how our services are used. We use this information to
        improve our services. This is mostly done via cookies, small files saved
        on your computer that contain information about your time on our
        website. How Google Analytics use your data is described in their{' '}
        <a href="https://www.google.com/analytics/terms/us.html">
          privacy policy
        </a>
        .
      </p>
    </div>
  )
}

PrivacyPolicy.propTypes = {}

export default PrivacyPolicy
