import React from 'react'
import Game from 'modules/Game'
import { DbQuize } from 'modules/Db'
import * as gameTypes from 'constants/gameTypes'

const GameDbQuize = () => {
  return (
    <Game gameType={gameTypes.DB_QUIZE} withBubble={false}>
      {({ next, points }) => (
        <DbQuize next={next} points={points} key={'db_quize'} />
      )}
    </Game>
  )
}

export default GameDbQuize
