import React, { useEffect, useRef } from 'react'
import { clearErrors } from 'actions/errors'
import { connect } from 'react-redux'
import { useSpring, animated as a } from 'react-spring'
import { ReactComponent as Close } from './Close.svg'
import PropTypes from 'prop-types'
import styles from './ErrorBanner.module.scss'

function ErrorBanner({ msg, show, clearErrors }) {
  const ref = useRef(null)
  const animProps = useSpring({
    transform: `translateY(${show ? 33 : 0}px)`,
    opacity: show ? 1 : 0
  })

  useEffect(() => {
    if (show) {
      ref.current = setTimeout(() => {
        clearErrors()
      }, 5000)
    }
    return () => clearTimeout(ref.current)
  }, [clearErrors, show])

  const handleClose = () => {
    clearErrors()
    clearTimeout(ref.current)
  }

  return (
    <a.div className={styles.container} style={animProps}>
      <div className={styles.center_container}>
        <span>Oops! {msg}</span>
      </div>
      <button className={styles.close} onClick={handleClose}>
        <Close />
      </button>
    </a.div>
  )
}

ErrorBanner.propTypes = {
  msg: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  show: PropTypes.bool.isRequired,
  clearErrors: PropTypes.func.isRequired
}

export default connect(state => ({ ...state.error }), { clearErrors })(
  ErrorBanner
)
