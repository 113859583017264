import React from 'react'
// import PropTypes from 'prop-types'

function Terms() {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p>Last updated: 20.11.2019</p>
      <p>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions")
        carefully before using the{' '}
        <a href="https://audiodrillz.app">audiodrillz.app</a>
        website.
      </p>
      <p>
        By accessing or using the Service you agree to be bound by these Terms.
        If you disagree with any part of the terms then you may not access the
        Service.
      </p>
      <p>
        "Audiodrillz" is a web application for sound producers, audioengeneers,
        musicians, DJs and everyone who wants to improve their sound listening
        skills.
      </p>
    </div>
  )
}

Terms.propTypes = {}

export default Terms
