import React, { PureComponent } from 'react'
import styles from './Game.module.scss'
import GameStatusBar from './GameStatusBar'
import Error from './Error'
import Start from './Start'
import GameOver from './GameOver'
import LevelComplete from './LevelComplete'
import Sounds from 'components/Sounds'
import Loading from 'components/LoadingGame'
import GenrePanel from 'components/GenrePanel'
import ResultBubble from 'components/ResultBubble'
import { connect } from 'react-redux'
import { QUESTIONS_PER_LEVEL, LEVELS_TOTAL } from 'constants/settings'
import {
  fetchGame,
  gameStart,
  nextQuestion,
  levelComplete,
  nextLevel,
  gameOver,
  switchTrack,
  gameComplete
} from 'actions'
import { getCurrentQuestionValueWithText } from 'reducers/questions'
import { isLoggedIn } from 'reducers/auth'
import Genres from 'constants/Genres'
import ReactCSSTransitionReplace from 'react-css-transition-replace'
import { soundsURL } from 'constants/URL'
import { isMobile } from 'react-device-detect'
import { translateToHuman } from 'utils/translateToHuman'

import PropTypes from 'prop-types'

class Game extends PureComponent {
  static propTypes = {
    bubble: PropTypes.object.isRequired,
    children: PropTypes.any,
    complete: PropTypes.bool.isRequired,
    currentQuestion: PropTypes.number.isRequired,
    err: PropTypes.bool.isRequired,
    fetchGame: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    gameComplete: PropTypes.func.isRequired,
    gameOver: PropTypes.func.isRequired,
    gameStart: PropTypes.func.isRequired,
    genre: PropTypes.string.isRequired,
    isLevelComplete: PropTypes.bool.isRequired,
    level: PropTypes.number.isRequired,
    levelComplete: PropTypes.func.isRequired,
    livesLeft: PropTypes.number.isRequired,
    livesTotal: PropTypes.number.isRequired,
    nextLevel: PropTypes.func.isRequired,
    nextQuestion: PropTypes.func.isRequired,
    over: PropTypes.bool.isRequired,
    questions: PropTypes.object.isRequired,
    score: PropTypes.number.isRequired,
    sounds: PropTypes.object.isRequired,
    switchTrack: PropTypes.func.isRequired,
    gameType: PropTypes.string.isRequired,
    ready: PropTypes.bool.isRequired,
    running: PropTypes.bool.isRequired,
    withBubble: PropTypes.bool,
    isLoggedIn: PropTypes.bool.isRequired
  }

  static defaultProps = {
    withBubble: true
  }

  constructor(props) {
    super(props)

    this.gameStart = this.gameStart.bind(this)
    this.fetchNewGame = this.fetchNewGame.bind(this)
    this.next = this.next.bind(this)
    this.handleGenreChange = this.handleGenreChange.bind(this)

    this.state = {
      containerWidth: 0,
      in: false
    }
  }

  componentDidMount() {
    this.props.fetchGame(this.props.gameType)
  }

  gameStart() {
    this.props.gameStart(this.props.gameType)
  }

  fetchNewGame() {
    this.props.fetchGame(this.props.gameType)
  }

  next(timeout = 3000) {
    setTimeout(() => {
      const { questions, currentQuestion, level } = this.props

      if (this.props.livesLeft <= 0) {
        this.props.gameOver()
        return
      }

      if (currentQuestion % QUESTIONS_PER_LEVEL === 0 && level < LEVELS_TOTAL) {
        this.props.levelComplete()

        setTimeout(() => {
          this.props.nextLevel()
          this.props.nextQuestion()
        }, 3000)
      } else if (questions[currentQuestion + 1]) {
        this.props.nextQuestion()
      } else {
        this.props.gameComplete()
      }
    }, timeout)
  }

  handleGenreChange(genre) {
    if (this.props.fetching) {
      return
    }
    this.props.switchTrack(genre)
  }

  renderContent() {
    const {
      fetching,
      ready,
      running,
      over,
      complete,
      err,
      isLevelComplete,
      bubble: { points }
    } = this.props

    if (fetching) {
      return <Loading key="loading" />
    }

    if (err) {
      return <Error key="error">{`Can't load this track at the moment`}</Error>
    }

    if (ready) {
      return <Start key="ready" onClick={this.gameStart} />
    }

    if (over) {
      return <GameOver key="gameover" fetchNewGame={this.fetchNewGame} />
    }

    if (complete) {
      return (
        <GameOver
          key="game_over"
          fetchNewGame={this.fetchNewGame}
          complete={true}
        />
      )
    }

    if (isLevelComplete) {
      return <LevelComplete key="next_level" />
    }

    if (running) {
      return (
        //render game element
        this.props.children({ next: this.next, points })
      )
    }
  }

  render() {
    const {
      livesTotal,
      livesLeft,
      score,
      level,
      genre,
      bubble,
      sounds,
      withBubble,
      isLoggedIn,
      gameType
    } = this.props

    return isMobile ? (
      <div className={styles.no_mobile}>
        <p>
          Unfortunatly Audiodrillz games isn't available on mobile yet. Please
          use the desktop version.
        </p>
      </div>
    ) : (
      <div className={styles.container}>
        <h1 className={styles.game_title}>{translateToHuman(gameType)}</h1>

        <Sounds
          src={`${soundsURL}sounds.mp3`}
          play={sounds.play}
          slice={sounds.slice}
        />
        <GameStatusBar
          livesTotal={livesTotal}
          livesLeft={livesLeft}
          score={score}
          level={level}
        />
        {withBubble && (
          <ResultBubble
            show={bubble.show}
            position={bubble.position}
            result={bubble.result}
            actualValue={bubble.actualValue}
            points={bubble.points}
          />
        )}
        <ReactCSSTransitionReplace
          className={styles.transition_container}
          transitionName="cross-fade"
          transitionEnterTimeout={600}
          transitionLeaveTimeout={600}
        >
          {this.renderContent()}
        </ReactCSSTransitionReplace>
        <GenrePanel
          genres={Genres}
          changeGenre={this.handleGenreChange}
          currentGenre={genre}
          isLoggedIn={isLoggedIn}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.game,
    isLoggedIn: isLoggedIn(state),
    bubble: {
      ...state.bubble,
      actualValue: getCurrentQuestionValueWithText(state)
    },
    sounds: state.sounds,
    questions: state.questions
  }
}

const mapDispatchToProps = {
  gameStart,
  nextQuestion,
  levelComplete,
  nextLevel,
  gameOver,
  fetchGame,
  switchTrack,
  gameComplete
}

export default connect(mapStateToProps, mapDispatchToProps)(Game)
