import React from 'react'
import styles from './MuteButton.module.scss'
import PropTypes from 'prop-types'

const MuteButton = ({ muted, onMute }) => {
  return (
      <div className={styles.container}>
        <label className={styles.mute}>
          <input type="checkbox" checked={muted} onChange={onMute} className={styles.checkbox} />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 28" className={styles.speaker}>
            <title>
              speaker
            </title>
            <path d="M2 9.66a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h4a2.78 2.78 0 0 1 1.71.71l5.59 5.59 1.21 1.21 1 1c.28.27.5.05.5-.5V1.16c0-.27-.32-.18-.71.21L7.71 8.95A2.78 2.78 0 0 1 6 9.66z" fill="none"/>
          </svg>
          <div className={styles.wave_one} />
          <div className={styles.wave_two} />
        </label>
      </div>
  )
}

export default MuteButton

MuteButton.propTypes = {
  muted: PropTypes.bool.isRequired,
  onMute: PropTypes.func.isRequired
}