export default [
  60,
  65,
  70,
  75,
  80,
  90,
  100,
  120,
  150,
  180,
  200,
  220,
  250,
  300,
  350,
  400,
  440,
  560,
  620,
  680,
  750,
  800,
  850,
  900,
  950,
  1000,
  1100,
  1250,
  1400,
  1500,
  1650,
  1800,
  2000,
  2200,
  2400,
  2600,
  3000,
  3500,
  4000,
  4500,
  5000,
  5500,
  6000,
  6500,
  7000,
  7500,
  8000,
  8500,
  9000,
  9500,
  10000,
  10500,
  11000,
  11500,
  12000,
  12500,
  13000,
  13500,
  14000,
  14500,
  15000
]
