import * as t from '../constants/actionTypes'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = {
  currentSlice: 0,
  spritesData: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE:
      return initialState
    case t.FETCH_GAME_SUCCESS:
      return {
        ...initialState,
        spritesData: payload.sprite
      }
    case t.FETCH_TRACK_SUCCESS:
      return {
        ...initialState,
        spritesData: payload.sprite
      }
    case t.NEXT_QUESTION: {
      let currentSlice

      const slicesLength = Object.keys(state.spritesData).length

      if (state.currentSlice >= slicesLength - 1) {
        currentSlice = 0
      } else {
        currentSlice = state.currentSlice + 1
      }

      return {
        ...state,
        currentSlice
      }
    }

    default:
      return state
  }
}

export const getCurrentSprite = state => state.sprites.spritesData

//TODO:
export const getCurrentSlice = state => {
  const sprite = getCurrentSprite(state)
  const slices = Object.keys(sprite)

  return slices[state.sprites.currentSlice]
}
