import { combineReducers } from 'redux'
import game from './game'
import questions from './questions'
import player from './player'
import sprites from './sprites'
import sounds from './sounds'
import bubble from './bubble'
import auth from './auth'
import rank from './rank'
import publicProfile from './publicProfile'
import confirmation from './confirmation'
import passwordReset from './passwordReset'
import avatarUpload from './avatarUpload'
import error from './error'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'

export default history =>
  combineReducers({
    game,
    questions,
    player,
    sprites,
    sounds,
    bubble,
    auth,
    confirmation,
    passwordReset,
    avatarUpload,
    rank,
    publicProfile,
    error,
    form: formReducer,
    router: connectRouter(history)
  })
