import React from 'react'
import styles from './ResultBubble.module.scss'
import PropTypes from 'prop-types'

const ResultBubble = ({ show, points, result, actualValue, position }) => {
  const renderPosition = position => {
    //assuming this magic number 300 is max possible width of the tooltip
    //(its min-width = 180px)
    if (position > 300) {
      return { left: position - 195 } //195 is width + gap
    } else {
      return { left: position + 15 } //15 is a gap
    }
  }

  const renderShow = points => points ? styles.show_right : styles.show_wrong

  return (
      <div
          className={show ? renderShow(points) : styles.container}
          style={renderPosition(position)}>
        <h4 className={styles.result}>{result}</h4>
        <p>Actual: {actualValue}</p>
        <p> + {points} points</p>
      </div>
  )

}

export default ResultBubble

ResultBubble.propTypes = {
  actualValue: PropTypes.string,
  points: PropTypes.number,
  position: PropTypes.number.isRequired,
  result: PropTypes.string,
  show: PropTypes.bool.isRequired
}
