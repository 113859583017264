import React from 'react'
import styles from './Switch.module.scss'
import PropTypes from 'prop-types'

const Switch = ({ nameOn, nameOff, isOn, onChange, isActive, bypassCount }) => (
  <div className={styles.container}>
    <span className={styles.name}>
      {nameOff}
      <span className={bypassCount > 0 ? styles.counter : styles.counter_zero}>
        left: {bypassCount}
      </span>
    </span>
    <div className={styles.switch}>
      <label htmlFor="switch" />
      <input
        type="checkbox"
        name="switch"
        id="switch"
        onChange={onChange}
        checked={isOn}
      />
      <div className={styles.handler}>
        <div className={isActive ? styles.light : styles.light_inactive} />
      </div>
    </div>
    <span className={styles.name}>{nameOn}</span>
  </div>
)

Switch.propTypes = {
  nameOn: PropTypes.string.isRequired,
  nameOff: PropTypes.string.isRequired,
  isOn: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  bypassCount: PropTypes.number
}

Switch.defaultProps = {
  isActive: true
}

export default Switch
