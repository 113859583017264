export const switchBypass = bypass => (
    {
      type: 'SWITCH_BYPASS',
      payload: bypass
    }
)

export const switchMute = muted => (
    {
      type: 'SWITCH_MUTE',
      payload: muted
    }
)
