import React, { PureComponent } from 'react'
import HowlerTunaCompressor from './HowlerTunaCompressor'
import { answer } from 'actions'
import { connect } from 'react-redux'
import ControlPanel from 'components/ControlPanel'
import { getCurrentSprite } from 'reducers/sprites'
import {
  getCurrentQuestionValue,
  getCurrentQuestionText,
  getCurrentQuestionType
} from 'reducers/questions'
import { getCurrentLevel, getCurrentQuestion } from 'reducers/game'
import { FaPowerOff } from 'react-icons/fa'
import classNames from 'classnames/bind'
import styles from './Compressor.module.scss'
import PropTypes from 'prop-types'
import Spectrum from 'components/Spectrum'

const cx = classNames.bind(styles)

export class Compressor extends PureComponent {
  static propTypes = {
    playing: PropTypes.bool.isRequired,
    answer: PropTypes.func.isRequired,
    delayTime: PropTypes.number,
    next: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    sprite: PropTypes.object,
    bypass: PropTypes.bool.isRequired,
    muted: PropTypes.bool.isRequired,
    currentQuestion: PropTypes.number.isRequired,
    allValues: PropTypes.object.isRequired,
    changingValues: PropTypes.array.isRequired,
    ratioByLevel: PropTypes.number.isRequired,
    points: PropTypes.number,
    legend: PropTypes.string.isRequired,
    questionType: PropTypes.string.isRequired
  }

  state = {
    nowPlaying: '1',
    answerIdx: null
  }

  handleNowPlaying = e => {
    this.setState({ nowPlaying: e.target.value })
  }

  getActual = values => {
    if (this.props.questionType === 'ATTACK') {
      return Math.min(...values)
    }
    return Math.max(...values)
  }

  handleAnswer = (answer, idx) => {
    if (!this.props.playing) {
      return
    }

    this.setState({ answerIdx: idx })

    const { changingValues } = this.props

    const actual = this.getActual(changingValues)

    if (answer === actual) {
      this.props.answer('PERFECT')
    } else {
      this.props.answer('FAIL')
    }

    this.props.next(5000)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuestion !== this.props.currentQuestion) {
      this.setState({ answerIdx: null })
    }
  }

  render() {
    const {
      allValues,
      changingValues,
      questionType,
      src,
      sprite,
      playing,
      bypass,
      muted,
      points,
      ratioByLevel,
      legend
    } = this.props

    const { nowPlaying, answerIdx } = this.state
    const { attack, release, ratio } = allValues

    const attackValue = Array.isArray(attack) ? attack[nowPlaying] : attack
    const releaseValue = Array.isArray(release) ? release[nowPlaying] : release
    const ratioValue = Array.isArray(ratio) ? ratio[nowPlaying] : ratioByLevel
    const actual = this.getActual(changingValues)

    return (
      <div>
        <HowlerTunaCompressor
          attack={attackValue}
          release={releaseValue}
          ratio={ratioValue}
          src={src}
          sprite={sprite}
          playing={playing}
          bypass={bypass}
          mute={muted}
        />
        <div className={styles.container}>
          <div className={styles.param_box}>
            {questionType !== 'ATTACK' && (
              <p>
                Attack: <strong>{attackValue}ms</strong>
              </p>
            )}
            {questionType !== 'RELEASE' && (
              <p>
                Release: <strong>{releaseValue}ms</strong>
              </p>
            )}

            {questionType !== 'RATIO' && (
              <p>
                Ratio: <strong>{ratioValue}:1</strong>
              </p>
            )}

            <p>
              Threshold: <strong>-20db</strong>
            </p>
          </div>
          <h1 className={styles.legend}>{legend}</h1>

          <div className={styles.options_box}>
            {changingValues.map((val, idx) => {
              const isCurrentPlaying = idx === +nowPlaying && playing
              const isCurrentAnswered = answerIdx === idx
              const switchClass = cx({
                option_switch: true,
                option_playing: isCurrentPlaying,
                right: isCurrentAnswered && points,
                wrong: isCurrentAnswered && !points
              })
              const actualClass = cx({
                actual: true,
                actual_right: actual === val
              })

              return (
                <div key={idx} className={styles.option}>
                  <div className={switchClass}>
                    {/* <h2 className={styles.option_title}>{idx + 1}</h2> */}
                    <input
                      id={idx}
                      name={idx}
                      type="checkbox"
                      checked={this.state.nowPlaying === idx.toString()}
                      onChange={this.handleNowPlaying}
                      value={idx}
                    />
                    <label htmlFor={idx}>
                      {answerIdx != null ? (
                        <div className={actualClass}>
                          {val}
                          {questionType === 'RATIO' ? ':1' : 'ms'}
                        </div>
                      ) : (
                        <div className={styles.play_icon}>
                          {isCurrentPlaying ? <Spectrum /> : <FaPowerOff />}
                        </div>
                      )}
                    </label>
                  </div>
                  <button
                    className={styles.answer_button}
                    onClick={() => this.handleAnswer(val, idx)}
                  >
                    Answer
                  </button>
                </div>
              )
            })}
          </div>
        </div>
        <ControlPanel
          bypassTextOn={'Compression on'}
          bypassTextOff={'Compression off'}
        />
        <p className={styles.tip}>
          Tip: for better results switch to a track with more distinct sounds
          e.g. drum loops or one shots.
        </p>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const level = getCurrentLevel(state)
  const allValues = getCurrentQuestionValue(state) || {}
  const changingValues =
    Object.values(allValues).filter(val => Array.isArray(val))[0] || []

  const calculatedLevel = allValues.ratio - (level - 1) * 2
  const ratioByLevel = calculatedLevel >= 2 ? calculatedLevel : 2

  return {
    level,
    ratioByLevel,
    currentQuestion: getCurrentQuestion(state),
    sprite: getCurrentSprite(state),
    allValues,
    changingValues,
    legend: getCurrentQuestionText(state),
    questionType: getCurrentQuestionType(state),
    ...state.player,
    ...state.bubble
  }
}

export default connect(mapStateToProps, { answer })(Compressor)
