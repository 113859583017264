import React, { PureComponent } from 'react'
import styles from './Display.module.scss'
import PropTypes from 'prop-types'
import { ReactComponent as Arrow } from './Arrows.svg'
import { debounce } from 'lodash'

class Display extends PureComponent {
  constructor(props) {
    super(props)

    this.onHeadMouseOver = this.onHeadMouseOver.bind(this)
    this.onHeadMouseMove = this.onHeadMouseMove.bind(this)
    this.onHeadClick = this.onHeadClick.bind(this)

    this.head = React.createRef()
    this.container = React.createRef()

    this.state = {
      headLeft: 0
    }
  }

  static propTypes = {
    position: PropTypes.number,
    setInitialPosition: PropTypes.func,
    onHeadPositionChange: PropTypes.func,
    onHeadClick: PropTypes.func.isRequired,
    grid: PropTypes.elementType,
    gridImg: PropTypes.node,
    children: PropTypes.element
  }

  componentDidMount() {
    const containerWidth = this.container.current.offsetWidth

    //set head to the container's middle if no other position directive from parent component
    if (this.props.position && this.props.setInitialPosition) {
      //initial position is calculated based on container width and scale specifics(e.g. logarithmic freq scale) specified in parent
      //container width goes through callback to the parent to avoid React.forwardRef()
      this.props.setInitialPosition(containerWidth)
    } else {
      const headWidth = this.head.current.offsetWidth
      const position = containerWidth / 2

      const headLeft = position - headWidth / 2

      this.setState({
        headLeft
      })
    }
  }

  componentDidUpdate(prevProps) {
    //set head to position directive from parent component
    if (prevProps.position !== this.props.position) {
      const headWidth = this.head.current.offsetWidth
      const position = this.props.position
      const headLeft = position - headWidth / 2

      this.setState({
        headLeft
      })
    }
  }

  getPositionToMove(e) {
    const containerLeft = this.container.current.getBoundingClientRect().left

    return e.pageX - containerLeft - this.head.current.offsetWidth / 2
  }

  onHeadMouseOver(e) {
    const headLeft = this.getPositionToMove(e)

    this.setState({ headLeft })
  }

  onHeadMouseMove(e) {
    const containerWidth = this.container.current.offsetWidth
    const headWidth = this.head.current.offsetWidth

    let headLeft = this.getPositionToMove(e)
    const rightEdge = containerWidth - headWidth / 2
    const leftEdge = 0 - headWidth / 2

    if (headLeft < leftEdge) {
      headLeft = leftEdge
    }

    if (headLeft > rightEdge) {
      headLeft = rightEdge
    }

    const needlePosition = headLeft + headWidth / 2

    this.setState({
      headLeft
    })

    if (this.props.onHeadPositionChange) {
      const debounced = debounce(this.props.onHeadPositionChange, 50)

      debounced(needlePosition, containerWidth)
    }
  }

  onHeadClick() {
    const position = this.state.headLeft + this.head.current.offsetWidth / 2

    this.props.onHeadClick(position)
  }

  render() {
    const Grid = this.props.grid

    return (
      <div className={styles.container} ref={this.container}>
        <Grid className={styles.grid} />
        <div
          ref={this.head}
          style={{ left: this.state.headLeft }}
          className={styles.head}
          onMouseOver={this.onHeadMouseOver}
          onMouseMove={this.onHeadMouseMove}
          onClick={this.onHeadClick}
        >
          {this.props.children}
          <div className={styles.arrow}>
            <Arrow />
          </div>
        </div>
      </div>
    )
  }
}

export default Display
