import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Distortion.module.scss'
import { connect } from 'react-redux'
import { getCurrentSprite } from 'reducers/sprites'
import { getCurrentQuestionValue } from 'reducers/questions'
import { getCurrentLevel, getCurrentQuestion } from 'reducers/game'
import { answer } from 'actions'
import HowlerTunaDistortion from './HowlerTunaDistortion'

import ControlPanel from 'components/ControlPanel'

import { CircleSlider } from 'react-circle-slider'
// import CircularSlider from '@fseehawer/react-circular-slider'
// import CircularSlider from 'react-circular-slider-svg'

export class Distortion extends Component {
  static propTypes = {
    currentQuestion: PropTypes.number.isRequired,
    distortion: PropTypes.number,
    answer: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    points: PropTypes.number,
    show: PropTypes.bool,
    result: PropTypes.string,
    src: PropTypes.string.isRequired,
    sprite: PropTypes.object.isRequired,
    playing: PropTypes.bool.isRequired,
    bypass: PropTypes.bool.isRequired,
    muted: PropTypes.bool.isRequired
  }

  state = {
    answer: 0
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleSpacePress)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleSpacePress)
  }

  handleSpacePress = e => {
    e.preventDefault()

    if (e.key === 'Spacebar' || e.key === ' ') {
      this.handleAnswer()
    }
  }

  handleSliderChange = value => {
    this.setState({
      answer: value
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuestion !== this.props.currentQuestion) {
      this.setState({ answer: 0 })
    }
  }

  handleAnswer = () => {
    if (!this.props.playing) {
      return
    }

    const { answer } = this.state
    const { distortion } = this.props

    const delta = Math.abs(distortion - answer)

    if (delta <= 5) {
      this.props.answer('PERFECT')
    } else if (delta <= 10) {
      this.props.answer('GOOD')
    } else {
      this.props.answer('FAIL')
    }

    this.props.next()
  }

  renderBubble() {
    const { points, show, result, distortion } = this.props

    const renderShow = points =>
      points ? styles.bubble_right : styles.bubble_wrong

    return (
      <div className={show ? renderShow(points) : styles.bubble}>
        <h1 className={styles.result}>{result}</h1>
        <p>Actual: {distortion}%</p>
        <p> + {points} points</p>
      </div>
    )
  }

  render() {
    const { answer } = this.state
    const { distortion, src, sprite, playing, bypass, muted } = this.props

    return (
      <div>
        <HowlerTunaDistortion
          distortion={distortion}
          src={src}
          sprite={sprite}
          playing={playing}
          bypass={bypass}
          mute={muted}
        />

        {this.renderBubble()}
        <div className={styles.container}>
          <div className={styles.slider_box}>
            <CircleSlider
              value={answer}
              onChange={this.handleSliderChange}
              size={260}
              knobRadius={18}
              progressWidth={10}
              circleWidth={10}
              knobColor={'#2b2c2e'}
              progressColor={'#fc4c72'}
              circleColor={'#ad9a95'}
              showPercentage={true}
              showTooltip={true}
              tooltipSize={48}
              tooltipColor={'#ad9a95'}
            />
          </div>

          <p className={styles.slider_label}>Distortion level</p>
          <button className={styles.answer_button} onClick={this.handleAnswer}>
            Click here or press Space to answer
          </button>
          {/* <p className={styles.hint}>
            Just click inside the slider circle if your answer is 0
          </p> */}
        </div>
        <ControlPanel bypassTextOn={'Dist on'} bypassTextOff={'Dist off'} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    level: getCurrentLevel(state),
    currentQuestion: getCurrentQuestion(state),
    sprite: getCurrentSprite(state),
    distortion: getCurrentQuestionValue(state),
    ...state.player,
    ...state.bubble
  }
}

export default connect(mapStateToProps, { answer })(Distortion)
