import React from 'react'
import Game from 'modules/Game'
import Pan from 'modules/Pan'
import * as gameTypes from 'constants/gameTypes'

const GamePan = () => {
  return (
    <Game gameType={gameTypes.PAN}>
      {({ next }) => <Pan next={next} key={'pan'} />}
    </Game>
  )
}

export default GamePan
