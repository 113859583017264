import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import ErrorBanner from 'components/ErrorBanner'
import Logo from 'components/Logo'
import { connect } from 'react-redux'
import styles from './PageHeaderMenu.module.scss'
import { logout } from 'actions/auth'
import { GiHamburgerMenu } from 'react-icons/gi'

import PropTypes from 'prop-types'

import Avatar from 'components/Avatar'

class PageHeaderMenu extends PureComponent {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    showError: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.linksData = [
      { name: 'Games', path: '/games', hidden: false },
      { name: 'Leaderboard', path: '/leaderboard', hidden: true },
      { name: 'Dashboard', path: '/dashboard', hidden: true },
      { name: 'Donate', path: '/donate', hidden: true },
    ]
  }

  state = {
    menuOpen: false,
  }

  handleMenuOpen = () =>
    this.setState((state) => ({ menuOpen: !state.menuOpen }))
  handleMenuBlur = () => {
    this.setState((state) => {
      if (state.menuOpen) {
        return { menuOpen: false }
      }
    })
  }

  renderLinks() {
    //show if unauthorized
    if (!this.props.isLoggedIn) {
      return this.linksData
        .filter(({ hidden }) => !hidden)
        .map(({ path, name }) => {
          return (
            <li className={styles.link_item} key={name}>
              <NavLink
                exact
                className={styles.link}
                activeClassName={styles.link_selected}
                to={path}
              >
                {name}
              </NavLink>
            </li>
          )
        })
    }

    //show if authorized
    return this.linksData.map(({ path, name }) => {
      return (
        <li className={styles.link_item} key={name}>
          <NavLink
            exact
            className={styles.link}
            activeClassName={styles.link_selected}
            to={path}
          >
            {name}
          </NavLink>
        </li>
      )
    })
  }

  renderLoginButton() {
    if (this.props.isLoggedIn) {
      return (
        <div className={styles.link_item}>
          <button className={styles.link} onClick={this.props.logout}>
            Logout
          </button>
        </div>
      )
    }

    return (
      <div className={styles.link_item}>
        <NavLink
          exact
          className={styles.link}
          activeClassName={styles.link_selected}
          to="/login"
        >
          Log in
        </NavLink>
      </div>
    )
  }

  renderSignUpButton() {
    return (
      !this.props.isLoggedIn && (
        <div className={styles.link_item}>
          <NavLink
            exact
            className={styles.link}
            activeClassName={styles.link_selected}
            to="/register"
          >
            Sign Up
          </NavLink>
        </div>
      )
    )
  }

  render() {
    const { avatar, username } = this.props.user
    const { menuOpen } = this.state
    const { isLoggedIn, showError } = this.props

    return (
      <div className={styles.container}>
        <nav className={menuOpen ? styles.nav_open : styles.nav}>
          <div className={styles.button_container}>
            <button className={styles.hamburger} onClick={this.handleMenuOpen}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={menuOpen ? styles.links_box : styles.links_box_close}>
            <ul className={styles.menu_list}>
              <li className={styles.link_item}>
                <NavLink exact className={styles.link_logo} to="/games">
                  <div className={styles.logo_container}>
                    <Logo />
                  </div>
                </NavLink>
              </li>
              {this.renderLinks()}

              {/* <li className={styles.auth_box}>
                {isLoggedIn && (
                  <div className={styles.avatar}>
                    <Avatar src={avatar} username={username} />
                  </div>
                )}

                {this.renderLoginButton()
                {this.renderSignUpButton()}
              </li> */}
            </ul>
          </div>
          {menuOpen && (
            <div
              className={styles.overlay_empty}
              onClick={this.handleMenuBlur}
            />
          )}
        </nav>

        <ErrorBanner show={showError} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isLoggedIn: state.auth.isLoggedIn,
    showError: state.error.show,
  }
}

export default connect(mapStateToProps, { logout })(PageHeaderMenu)
