import React from 'react'
import Game from 'modules/Game'
import Eq from 'modules/Eq'
import * as gameTypes from 'constants/gameTypes'

const GameEqPeak = () => {
  return (
    <Game gameType={gameTypes.EQ_PEAK}>
      {({ next }) => (
        <Eq
          next={next}
          filterType={'peaking'}
          key={'eq_peak'}
          gameType={gameTypes.EQ_PEAK}
        />
      )}
    </Game>
  )
}

export default GameEqPeak
