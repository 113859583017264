import React from 'react'
import { Route, Switch, Link } from 'react-router-dom'
import PageHeaderMenu from 'components/PageHeaderMenu'
import Footer from 'components/Footer'

import Games from '../Games'

import GameEqPeak from '../GameEqPeak'
import GameEqCut from '../GameEqCut'
import GamePan from '../GamePan'
import GameDbQuize from '../GameDbQuize'
import GameDbPro from '../GameDbPro'
import GameFilterType from '../GameFilterType'
import GameDelayTime from '../GameDelayTime'
import GameDistortion from '../GameDistortion'
import GameReverb from '../GameReverb'
import GameCompressorAttack from '../GameCompressorAttack'
import GameCompressorRelease from '../GameCompressorRelease'
import GameCompressorRatio from '../GameCompressorRatio'

// import Login from '../Login'
// import SignUp from '../SignUp'
// import Dashboard from '../Dashboard'
// import Rank from '../Rank'
// import ConfirmEmail from '../ConfirmEmail'
import Contact from '../Contact'
import Terms from '../Terms'
// import Donate from '../Donate'

// import PublicProfile from '../PublicProfile'

import styles from './PageWithHeader.module.scss'
// import Route from 'components/Route'
// import { PasswordForgot, PasswordReset } from '../PasswordReset'
import ErrorPage from '../ErrorPage'
import PrivacyPolicy from 'pages/PrivacyPolicy'

// import PropTypes from 'prop-types'

const PageWithHeader = () => {
  return (
    <div className={styles.container}>
      {/* Empty route injects location property to avoid updating blocking in menu.
      For more information see:
      //https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
      */}
      <Route component={PageHeaderMenu} />

      <div className={styles.container_center}>
        <div className={styles.content}>
          <Switch>
            <Route path="/games" component={Games} />

            {/* Game pages */}
            <Route path="/eq-peak" component={GameEqPeak} />
            <Route path="/eq-cut" component={GameEqCut} />
            <Route path="/pan" component={GamePan} />
            <Route path="/db-quize" component={GameDbQuize} />
            <Route path="/db-pro" component={GameDbPro} />
            <Route path="/filter-type" component={GameFilterType} />
            <Route path="/delay-time" component={GameDelayTime} />
            <Route path="/distortion" component={GameDistortion} />
            <Route path="/reverb" component={GameReverb} />
            <Route path="/compressor-attack" component={GameCompressorAttack} />
            <Route
              path="/compressor-release"
              component={GameCompressorRelease}
            />
            <Route path="/compressor-ratio" component={GameCompressorRatio} />

            {/* Auth pages */}
            {/* <Route path="/login" component={Login} />
            <Route path="/register" component={SignUp} />
            <Route path="/confirmation/:token" component={ConfirmEmail} />
            <Route path="/confirmation" exact component={ConfirmEmail} />
            <Route path="/forgot" component={PasswordForgot} />
            <Route path="/reset/:token" component={PasswordReset} /> */}

            {/* <Route path="/public-profile/:id" component={PublicProfile} /> */}

            {/* <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/leaderboard" component={Rank} /> */}

            {/* <Route path="/donate" component={Donate} /> */}
            <Route path="/contact" component={Contact} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />

            <Route path="/error-page" component={ErrorPage} />
            <Route component={() => <div>404 Page Not Found</div>} />
          </Switch>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default PageWithHeader
