export const points = {
  PERFECT_POINTS: 10,
  GOOD_POINTS: 5
}

export const LIVES_TOTAL = 4
export const LEVELS_TOTAL = 5
export const QUESTIONS_PER_LEVEL = 5
export const NUMBER_OF_QUESTIONS = LEVELS_TOTAL * QUESTIONS_PER_LEVEL

//rank endless scroll
export const PER_PAGE = 100
export const TOTAL_LIMIT = 1000
