import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Rings } from './puff.svg'
import { ReactComponent as Dots } from './three-dots.svg'

import styles from './Loading.module.scss'
import PropTypes from 'prop-types'

const Loading = ({
  loading,
  swapable,
  children,
  delay = 300,
  size = '24px',
  label,
  type = 'rings'
}) => {
  const [loadingState, setLoading] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    if (!loading) {
      ref.current = setTimeout(() => {
        setLoading(false)
      }, delay)
    } else {
      setLoading(true)
    }

    return () => clearTimeout(ref.current)
  }, [loading, delay])

  const renderIcon = type => {
    const props = {
      stroke: 'currentColor',
      fill: 'currentColor',
      width: '100%',
      className: styles.icon
    }

    const animations = {
      rings: <Rings {...props} />,
      dots: <Dots {...props} />
    }

    return animations[type]
  }

  return (
    <div
      style={{
        width: size || 'auto',
        flexShrink: 0
      }}
    >
      {loadingState || swapable ? (
        <div className={styles.container}>
          {label && <span className={styles.label}>{label}</span>}
          {renderIcon(type)}
        </div>
      ) : (
        children
      )}
    </div>
  )
}

Loading.propTypes = {
  loading: PropTypes.bool,
  swapable: PropTypes.bool,
  children: PropTypes.element,
  delay: PropTypes.number,
  size: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['rings', 'dots'])
}

export default Loading
