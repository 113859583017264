import {
  REGISTRATION_REQUEST,
  REGISTRATION_FAILURE,
  REGISTRATION_SUCCESS,
  EMAIL_CONFIRMATION_SUCCESS,
  EMAIL_CONFIRMATION_EXPIRED,
  EMAIL_CONFIRMATION_REQUEST,
  EMAIL_RESEND_REQUEST,
  EMAIL_RESEND_SUCCESS,
  EMAIL_RESEND_FAILURE,
  AUTH_REQUEST,
  LOGOUT
} from 'constants/actionTypes'

import { path } from 'ramda'

const initialState = {
  isLoading: false,
  isVerified: false,
  email: '',
  expired: false,
  resending: false,
  captchaReset: false
}

const confirmation = (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_REQUEST:
      return {
        ...state,
        email: payload
      }
    case REGISTRATION_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case REGISTRATION_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        email: payload,
        expired: false
      }
    case EMAIL_CONFIRMATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        email: payload,
        isVerified: false
      }
    case EMAIL_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isVerified: true
      }
    case EMAIL_CONFIRMATION_EXPIRED:
      return {
        ...state,
        isLoading: false,
        expired: true
      }
    case EMAIL_RESEND_REQUEST:
      return {
        ...state,
        isLoading: true,
        resending: true
      }
    case EMAIL_RESEND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resending: false
      }
    case EMAIL_RESEND_FAILURE:
      return {
        ...state,
        isLoading: false,
        resending: false
      }
    case LOGOUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default confirmation

export const isVerified = state => path(['confirmation', 'isVerified'], state) //state.confirmation.isVerified,
export const isLoading = state => path(['confirmation', 'isLoading'], state) ////state.confirmation.isLoading,
