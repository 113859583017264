import React from 'react'
import styles from './GameOver.module.scss'
import { Link } from 'react-router-dom'
import { ReactComponent as IconSadCat } from './icons/IconSadCat.svg'
import { ReactComponent as IconHappyCat } from './icons/IconHappyCat.svg'

import PropTypes from 'prop-types'

const GameOver = ({ fetchNewGame, complete }) => {
  const renderMessage = () => {
    if (complete) {
      return (
        <div>
          <div className={styles.icon}>
            <IconHappyCat />
          </div>
          <h1 className={styles.title}>Congrats!</h1>

          <p className={styles.description}>You've completed the drill.</p>
        </div>
      )
    } else {
      return (
        <div>
          <div className={styles.icon}>
            <IconSadCat />
          </div>
          <h1 className={styles.title}>Game Over</h1>
        </div>
      )
    }
  }

  return (
    <div className={styles.container}>
      {renderMessage()}
      <div>
        <button className={styles.button} onClick={fetchNewGame}>
          {complete ? 'Play again' : 'Try again'}
        </button>
        <span>or</span>
        <Link className={styles.button} to="/games">
          Play some other game
        </Link>
      </div>
      {/* <div className={styles.donate}>
        <Link className={styles.donateLink} to="/donate">
          Support Audiodrillz by making a donation
        </Link>
      </div> */}
    </div>
  )
}

export default GameOver

GameOver.propTypes = {
  complete: PropTypes.bool.isRequired,
  fetchNewGame: PropTypes.func.isRequired,
}

GameOver.defaultProps = {
  complete: false,
}
