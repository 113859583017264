export const EQ_PEAK =
  'One frequency of a source track is being boosted by applying a Bell curve. Guess what frequency is being boosted.'
export const EQ_CUT =
  'One frequency of a source track is being suppressed by applying a Bell curve. Guess what frequency is being suppressed.'
export const DB_PRO =
  'A source track is being processed by lowering or raising its volume level. Guess the exact value in dB of the processing being applied.'
export const DB_QUIZE =
  'A source track is being processed by lowering or raising its volume level. Choose the exact value in dB of the processing being applied from given options.'
export const FILTER_TYPE =
  'A source track is being processed through a filter of a particular type. Guess what kind of filter is being used.'
export const PAN =
  'A source track is being shifted either left or right in the stereo field. Guess the exact stereo panorama setting being applied.'
export const DELAY_TIME =
  'A source track is being proccessed through Delay effect. Guess the exact delay time in milliseconds.'
export const DISTORTION =
  'A source track is being proccessed through Distortion effect. Guess the amount of distortion being applied.'
export const COMPRESSOR_ATTACK =
  'Listen to three different sets of compression and guess in which case the attack is the fastest'
export const COMPRESSOR_RELEASE =
  'Listen to three different sets of compression and guess in which case the release is the slowest'
export const COMPRESSOR_RATIO =
  'Listen to three different sets of compression and guess in which case the ratio is the highest'
export const REVERB =
  'Reverb effect is being applied to a source track. Guess what settings are being used.'
