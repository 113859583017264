import {
  PASSWORD_RESET_REQUEST,
  RESET_REQUEST_SUCCESS,
  RESET_REQUEST_FAILURE,
  SEND_RESET_AGAIN,
  RESET_CONFIRMATION_EXPIRED,
  RESET_CONFIRMATION_SUCCESS,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAILURE,
  INVALID_TOKEN,
  LOGOUT
} from '../constants/actionTypes'

import { path } from 'ramda'

const initialState = {
  isLoading: false,
  sent: false,
  expired: false,
  confirmed: false,
  passwordChanged: false,
  invalidToken: false
}

export default (state = initialState, { type }) => {
  switch (type) {
    case PASSWORD_RESET_REQUEST:
      return {
        ...initialState,
        isLoading: true
      }
    case RESET_REQUEST_SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        sent: true
      }

    case RESET_REQUEST_FAILURE: {
      return {
        ...initialState
      }
    }
    case SEND_RESET_AGAIN:
      return {
        ...initialState,
        sent: false
      }
    case RESET_CONFIRMATION_EXPIRED:
      return {
        ...state,
        expired: true
      }
    case RESET_CONFIRMATION_SUCCESS:
      return {
        ...state,
        confirmed: true,
        expired: false
      }
    case PASSWORD_CHANGE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        passwordChanged: true
      }
    case PASSWORD_CHANGE_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case INVALID_TOKEN:
      return {
        ...initialState,
        invalidToken: true
      }
    case LOGOUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export const passwordChanged = state =>
  path(['passwordReset', 'passwordChanged'], state) //state.passwordReset.passwordChanged

export const resetSent = state => path(['passwordReset', 'sent'], state) //state.passwordReset.sent
export const isLoading = state => path(['passwordReset', 'isLoading'], state)
