import React, { PureComponent } from 'react'
import styles from './Quize.module.scss'

class Quize extends PureComponent {
  state = {
    result: ''
  }

  onAnswer = e => {
    const { result } = this.state
    const value = e.target.value

    if (result) {
      return
    }

    this.setState({
      result: value,
      [e.target.name]: e.target.checked
    })

    this.props.onAnswer(parseFloat(value))
  }

  renderTooltip(isWrong) {
    const { actual, points } = this.props

    return (
      <div className={styles.tooltip}>
        {isWrong ? (
          <>
            <p>WRONG :(</p>
            <p>Actual: {actual} </p>
            <p>Points: {points}</p>
          </>
        ) : (
          <>
            <p>RIGHT!</p>
            <p>Points: {points}</p>
          </>
        )}
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        result: '',
        a: '',
        b: '',
        c: '',
        d: ''
      })
    }
  }

  render() {
    const { result } = this.state
    const { a, b, c, d } = this.props.data
    const { actual, bg: Bg } = this.props

    const WRONG = parseFloat(result) !== parseFloat(actual)
    const labelClass = WRONG ? styles.wrong : styles.right

    return (
      <div className={styles.container}>
        <input
          id="a"
          name="a"
          type="checkbox"
          checked={!!this.state.a}
          onChange={this.onAnswer}
          value={a}
        />
        <label className={labelClass} htmlFor="a">
          <div className={styles.value}>{a}</div>
          {this.renderTooltip(WRONG)}
          <Bg className={styles.bg} />
        </label>

        <input
          id="b"
          name="b"
          type="checkbox"
          checked={!!this.state.b}
          onChange={this.onAnswer}
          value={b}
        />
        <label className={labelClass} htmlFor="b">
          <div className={styles.value}>{b}</div>
          {this.renderTooltip(WRONG)}
          <Bg className={styles.bg} />
        </label>

        <input
          id="c"
          name="c"
          type="checkbox"
          checked={!!this.state.c}
          onChange={this.onAnswer}
          value={c}
        />
        <label className={labelClass} htmlFor="c">
          <div className={styles.value}>{c}</div>
          {this.renderTooltip(WRONG)}
          <Bg className={styles.bg} />
        </label>

        <input
          id="d"
          name="d"
          type="checkbox"
          checked={!!this.state.d}
          onChange={this.onAnswer}
          value={d}
        />
        <label className={labelClass} htmlFor="d">
          <div className={styles.value}>{d}</div>
          {this.renderTooltip(WRONG)}
          <Bg className={styles.bg} />
        </label>
      </div>
    )
  }
}

export default Quize
