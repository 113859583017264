export const saveToLocalStorage = (name, value) => {
  try {
    localStorage.setItem(name, value)
  } catch (err) {
    console.error(err)
  }
}

export const getFromLocalStorage = name => {
  try {
    return localStorage.getItem(name)
  } catch (err) {
    console.error(err)
  }
}

export const clearToken = () => {
  try {
    localStorage.removeItem('user')
  } catch (err) {
    console.error(err)
  }
}

export const isValidSession = decodedToken => {
  if (!decodedToken) {
    return false
  }

  return decodedToken.exp > Date.now() / 1000
}
