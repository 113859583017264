import React from 'react'
import styles from './TooltipDisplayHead.module.scss'
import PropTypes from 'prop-types'

const TooltipDisplayHead = ({ children, position }) => {
  return <div className={position === 'right' ? styles.tooltip_right : styles.tooltip}> {children} </div>
}

export default TooltipDisplayHead

TooltipDisplayHead.propTypes = {
  children: PropTypes.any,
  position: PropTypes.string
}
