import React from 'react'
import styles from './Error.module.scss'
import PropTypes from 'prop-types'

const Error = ({ children }) => {
  return (
    <div className={styles.container}>
      <p className={styles.icon}>{'\uD83D\uDE3F'}</p>
      <p>{children}</p>
    </div>
  )
}

export default Error

Error.propTypes = {
  children: PropTypes.any
}