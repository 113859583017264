import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PageWithHeader from 'pages/PageWithHeader'
import Welcome from 'pages/Welcome'

function App() {
  return (
    <Switch>
      <Route path="/" component={Welcome} exact />
      <Route component={PageWithHeader} />
    </Switch>
  )
}

export default App
