import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Tuna from 'tunajs'

import withHowler from 'components/HowlerTuna/'

class HowlerTunaDistortion extends PureComponent {
  static propTypes = {
    distortion: PropTypes.number,
    bypass: PropTypes.bool,
    howl: PropTypes.object,
    howler: PropTypes.object.isRequired
  }

  static defaultProps = {
    bypass: false,
    distortion: 0
  }

  componentDidUpdate(prevProps) {
    const { bypass, howl, distortion } = this.props

    if (howl && prevProps.howl !== this.props.howl) {
      this.initDistortion(distortion)
    }

    if (distortion !== prevProps.distortion) {
      this.updateDistortion(distortion)
    }

    if (bypass !== prevProps.bypass) {
      this.updateBypass(bypass)
    }
  }

  initDistortion(distortion) {
    const ctx = this.props.howler.ctx
    const tuna = new Tuna(ctx)
    const howlNode = this.props.howl._sounds[0]._node

    const curveAmount = distortion / 100

    this.distortion = new tuna.Overdrive({
      outputGain: -10, //-42 to 0 in dB
      curveAmount, //0 to 1
      algorithmIndex: 2 //0 to 5, selects one of our drive algorithms
    })

    howlNode.disconnect(this.props.howler.masterGain)
    howlNode.connect(this.distortion)
    this.distortion.connect(this.props.howler.masterGain)
  }

  updateDistortion(distortion) {
    this.distortion.curveAmount = distortion / 100
  }

  updateBypass(bypass) {
    this.distortion.bypass = bypass
  }

  render() {
    return null
  }
}

export default withHowler(HowlerTunaDistortion)
