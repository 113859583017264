import { PureComponent } from 'react'
import withHowler from 'components/HowlerTuna/'
import Tuna from 'tunajs'
import PropTypes from 'prop-types'
import StereoPannerNode from 'stereo-panner-node'

StereoPannerNode.polyfill()

class TunaPan extends PureComponent {
  static propTypes = {
    bypass: PropTypes.bool.isRequired,
    howl: PropTypes.object,
    howler: PropTypes.object.isRequired,
    panPosition: PropTypes.number.isRequired
  }

  componentDidUpdate(prevProps) {
    const { panPosition, bypass, howl } = this.props

    if (howl && prevProps.howl !== this.props.howl) {
      this.initPan()
    }

    if (bypass !== prevProps.bypass) {
      this.updateBypass(bypass)
    }

    if (panPosition !== prevProps.panPosition) {
      this.updatePanPosition(panPosition)
    }
  }

  initPan() {
    const { panPosition } = this.props

    const ctx = this.props.howler.ctx
    const tuna = new Tuna(ctx)
    const howlNode = this.props.howl._sounds[0]._node

    //For this to work in Safari and IE, you need to polyfill StereoPannerNode using stereo-panner-node
    this.panner = new tuna.Panner({
      pan: panPosition // -1 (left) to 1 (right)
    })

    howlNode.disconnect(this.props.howler.masterGain)
    howlNode.connect(this.panner)
    this.panner.connect(this.props.howler.masterGain)
  }

  updatePanPosition(panPosition) {
    this.panner.pan = panPosition
  }

  updateBypass(bypass) {
    this.panner.bypass = bypass
  }

  render() {
    return null
  }
}

export default withHowler(TunaPan)
