import React from 'react'
import styles from './Footer.module.scss'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FaInstagram, FaFacebook, FaTwitter, FaDonate } from 'react-icons/fa'

function Footer({ position }) {
  const year = new Date().getFullYear()

  const positionStyle = {
    position: position ? 'fixed' : 'block',
  }

  if (position) positionStyle[position] = 0

  return (
    <footer className={styles.container} style={positionStyle}>
      {/* <div className={styles.footer_section}>
        <div className={styles.link_container}>
          <a href="mailto:audiodrillz@gmail.com">Contact</a>
        </div>

        <div className={styles.link_container}>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>

        <div>
          <Link to="/terms-temp">Terms of use</Link>
        </div>
      </div> */}

      {/* <div className={styles.social}>
        <a
          href="https://www.facebook.com/audiodrillz"
          className={styles.social_link}
        >
          <FaFacebook />
        </a>
        <a
          href="https://twitter.com/audiodrillz"
          className={styles.social_link}
        >
          <FaTwitter />
        </a>
        <a
          href="https://www.instagram.com/audiodrillz.app/"
          className={styles.social_link}
        >
          <FaInstagram />
        </a>
      </div> */}
      {/* <div className={styles.donate}>
        <div className={styles.link_container}>
          <Link to="/donate" className={styles.donateLink}>
            <span>Make a donation</span>
            <span className={styles.donateIcon}>
              <FaDonate />
            </span>
          </Link>
        </div>
      </div> */}
      <div className={styles.copyright_box}>
        <span className={styles.copyright}>© </span>
        {year} Audiodrillz
      </div>
    </footer>
  )
}

Footer.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom']),
}

export default Footer
