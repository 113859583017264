import React from 'react'
import GameCard from 'components/GameCard'
import styles from './Games.module.scss'
import CardPeak from './CardPeak.svg'
import CardCut from './CardCut.svg'
import CardPan from './CardPan.svg'
import CardFilterType from './CardFilterType.svg'
import CardDbPro from './CardDbPro.svg'
import CardDelayTime from './CardDelayTime.svg'
import CardReverb from './CardReverb.svg'
import CardDbQuize from './CardDbQuize.svg'
import CardDistortion from './CardDistortion.svg'
import CardCompressionRatio from './CardCompressionRatio.svg'
import CardCompressionAttack from './CardCompressionAttack.svg'
import CardCompressionRelease from './CardCompressionRelease.svg'

import * as gameDesc from 'constants/gameDesc'

const Games = () => {
  return (
    <div className={styles.games_container}>
      <GameCard
        src={'/eq-peak'}
        name="EQ PEAK"
        bgSrc={CardPeak}
        desc={gameDesc.EQ_PEAK}
      />
      <GameCard
        src={'/db-quize'}
        name="DB QUIZE"
        bgSrc={CardDbQuize}
        desc={gameDesc.DB_QUIZE}
      />
      <GameCard src={'/pan'} name="PAN" bgSrc={CardPan} desc={gameDesc.PAN} />
      <GameCard
        src={'/filter-type'}
        name="FILTER TYPE"
        bgSrc={CardFilterType}
        desc={gameDesc.FILTER_TYPE}
      />
      <GameCard
        src={'/db-pro'}
        name="DB PRO"
        bgSrc={CardDbPro}
        desc={gameDesc.DB_PRO}
      />
      <GameCard
        src={'/eq-cut'}
        name="EQ CUT"
        bgSrc={CardCut}
        desc={gameDesc.EQ_CUT}
      />
      <GameCard
        src={'/compressor-ratio'}
        name="COMPRESSOR RATIO"
        bgSrc={CardCompressionRatio}
        desc={gameDesc.COMPRESSOR_RATIO}
        published={true}
      />
      <GameCard
        src={'/compressor-attack'}
        name="COMPRESSOR ATTACK"
        bgSrc={CardCompressionAttack}
        desc={gameDesc.COMPRESSOR_ATTACK}
        published={true}
      />
      <GameCard
        src={'/compressor-release'}
        name="COMPRESSOR RELEASE"
        bgSrc={CardCompressionRelease}
        desc={gameDesc.COMPRESSOR_RELEASE}
        published={true}
      />
      <GameCard
        src={'/distortion'}
        name="DISTORTION"
        bgSrc={CardDistortion}
        desc={gameDesc.DISTORTION}
      />
      <GameCard
        src={'/delay-time'}
        name="DELAY TIME"
        bgSrc={CardDelayTime}
        desc={gameDesc.DELAY_TIME}
      />
      <GameCard
        src={'/reverb'}
        name="REVERB"
        bgSrc={CardReverb}
        desc={gameDesc.REVERB}
      />
    </div>
  )
}

export default Games
