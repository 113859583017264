import * as t from 'constants/actionTypes'
import * as settings from 'constants/settings'
import { points, LEVELS_TOTAL } from 'constants/settings'
import { LOCATION_CHANGE } from 'connected-react-router'
import { path } from 'ramda'

const initialState = {
  // gameType: '' //TODO: need refactoring - for now actual including this prop to initial state will interfere with gameType passed as a prop to the Game component
  gameToken: null,
  fetching: false,
  err: false,
  ready: false,
  running: false,
  genre: 'default',
  level: 1,
  isLevelComplete: false,
  bypassCount: LEVELS_TOTAL,
  livesTotal: settings.LIVES_TOTAL,
  livesLeft: settings.LIVES_TOTAL,
  currentQuestion: 1,
  score: 0,
  over: false,
  complete: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE:
      return initialState
    case t.FETCH_GAME_START:
      return {
        ...initialState,
        genre: state.genre, //preserve genre between  games unless switched
        fetching: true,
        err: false
      }
    case t.FETCH_GAME_SUCCESS:
      return {
        ...state,
        gameToken: payload.gameToken,
        ready: true,
        fetching: false,
        err: false
      }
    case t.FETCH_GAME_FAILURE:
      return {
        ...state,
        fetching: false,
        err: true
      }
    case t.FETCH_TRACK_START:
      return {
        ...state,
        fetching: true,
        genre: payload,
        err: false
      }
    case t.FETCH_TRACK_SUCCESS:
      return {
        ...state,
        fetching: false,
        err: false
      }
    case t.FETCH_TRACK_FAILURE:
      return {
        ...state,
        fetching: false,
        err: true
      }
    case t.GAME_START:
      return {
        ...state,
        ready: false,
        running: true,
        gameType: payload.gameType
      }
    case t.COUNT_BYPASS:
      return {
        ...state,
        bypassCount: state.bypassCount - 1
      }
    case t.ANSWER:
      return processAnswer(payload, state)
    case t.NEXT_QUESTION:
      return {
        ...state,
        currentQuestion: state.currentQuestion + 1,
        bypassCount: LEVELS_TOTAL - (state.level - 1)
      }
    case t.LEVEL_COMPLETE:
      return {
        ...state,
        isLevelComplete: true
      }
    case t.NEXT_LEVEL:
      return {
        ...state,
        level: state.level + 1,
        isLevelComplete: false,
        bypassCount: LEVELS_TOTAL - (state.level - 1)
      }
    case t.GAME_OVER:
      return {
        ...state,
        running: false,
        over: true
      }
    case t.GAME_COMPLETE:
      return {
        ...state,
        running: false,
        complete: true
      }
    default:
      return state
  }
}

const processAnswer = (payload, state) => {
  switch (payload.answerType) {
    case 'PERFECT':
      return {
        ...state,
        score: state.score + points.PERFECT_POINTS
      }
    case 'GOOD':
      return {
        ...state,
        score: state.score + points.GOOD_POINTS
      }
    default: {
      return {
        ...state,
        livesLeft: state.livesLeft > 0 ? state.livesLeft - 1 : state.livesLeft
      }
    }
  }
}

export const getCurrentLevel = state => path(['game', 'level'], state)
export const getCurrentQuestion = state =>
  path(['game', 'currentQuestion'], state)
