import Random from 'random-js'
import axios from 'axios'
import { getPrivateSpriteData, getPublicSpriteData } from './spriteData'
import eqQuestionsData from 'constants/eqQuestionsData'
import * as gameTypes from 'constants/gameTypes'
import { NUMBER_OF_QUESTIONS } from 'constants/settings'
import { soundsURL } from 'constants/URL'
import delayTimes from 'constants/delayTimeData'
import decayTimes from 'constants/reverbTimeData'
import axiosRetry from 'axios-retry'
// import load from 'audio-loader'

const apiURL = process.env.REACT_APP_API_URL || ''

const random = new Random(Random.engines.mt19937().autoSeed())

const createQuestionsStructure = (func, numberOfQuestions, data) => {
  const questions = {}

  for (let i = 1; i <= numberOfQuestions; i++) {
    Object.assign(questions, { [i]: func(data) })
  }

  return questions
}

/*
 * Render random freq
 * @param { Array } data - array of predefined frequencies
 * @returns { Object }
 * */
const getEqQuestion = (data) => {
  return { value: random.pick(data) }
}

const getPanQuestion = () => {
  const value = random.integer(-100, 100)
  let text = ''

  if (value > 0) text = 'Right'
  if (value < 0) text = 'Left'

  return { value, text }
}

const getDbQuestion = () => {
  return { value: random.integer(10, 200) / 100 }
}

const getFilterTypeQuestions = () => {
  const types = [
    'Low pass',
    'High pass',
    'Band pass',
    'Low shelf',
    'High shelf',
    'Peaking',
    'Notch',
    'All pass',
  ]

  const type = random.pick(types)
  const filterType = type.split(' ').join('').toLocaleLowerCase()

  let gain, frequency

  switch (filterType) {
    case 'highpass':
      gain = 0
      frequency = random.pick([80, 100, 200, 400, 600, 1000])
      break
    case 'lowpass':
      gain = 0
      frequency = random.pick([1000, 3000, 5000, 10000, 12000])
      break
    case 'bandpass':
    case 'allpass':
      gain = 0
      frequency = random.pick([400, 1000, 3000, 5000, 8000])
      break
    case 'peaking':
      gain = 6
      frequency = random.pick(eqQuestionsData)
      break
    case 'notch':
      gain = 0
      frequency = random.pick(eqQuestionsData)
      break
    case 'lowshelf':
      gain = random.pick([-6, 6])
      frequency = random.integer(60, 200)
      break
    case 'highshelf':
      gain = random.pick([-6, 6])
      frequency = random.pick([3500, 4000, 5000, 6000, 8000, 10000])
      break
    default:
      gain = 0
      frequency = 1000
  }

  return {
    value: { filterType, gain, frequency },
    text: type,
  }
}

const getDelayTimeQuestions = (delayTimes) => {
  return {
    value: random.pick(delayTimes),
  }
}

const getReverbQuestions = (decayTimes) => {
  return {
    value: random.pick(decayTimes),
  }
}

const getDistortionQuestions = () => {
  return {
    value: random.integer(0, 100),
  }
}

const getCompressorAttackQuestions = () => {
  const food = [0, 5, 10, 20, 30, 40, 50]

  const values = random.sample(food, 3)
  return {
    value: {
      attack: values,
      release: 200,
      ratio: 10,
    },
    text: 'Guess in which case the compression attack is the fastest',
    type: 'ATTACK',
  }
}

const getCompressorReleaseQuestions = () => {
  const food = [10, 20, 50, 80, 100, 120, 150, 180, 200, 220, 250, 300]

  const values = random.sample(food, 3)
  return {
    value: {
      attack: 0,
      release: values,
      ratio: 10,
    },
    text: 'Guess in which case the compression release is the slowest',
    type: 'RELEASE',
  }
}

const getCompressorRatioQuestions = () => {
  const food = [2, 4, 6, 8, 10, 12, 20]

  const values = random.sample(food, 3)
  return {
    value: {
      attack: 0,
      release: 250,
      ratio: values,
    },
    text: 'Guess in which case the compression ratio is the highest',
    type: 'RATIO',
  }
}

const partiallyApply =
  (fn, ...argsToBind) =>
  (...args) =>
    fn(...args, ...argsToBind)

const createFixedNumberStructure = partiallyApply(
  createQuestionsStructure,
  NUMBER_OF_QUESTIONS
)

export const getQuestions = (gameType) => {
  switch (gameType) {
    case gameTypes.EQ_PEAK:
    case gameTypes.EQ_CUT:
      return createQuestionsStructure(
        getEqQuestion,
        NUMBER_OF_QUESTIONS,
        eqQuestionsData
      )
    case gameTypes.PAN:
      return createFixedNumberStructure(getPanQuestion)
    case gameTypes.DB_QUIZE:
    case gameTypes.DB_PRO:
      return createFixedNumberStructure(getDbQuestion)
    case gameTypes.FILTER_TYPE:
      return createFixedNumberStructure(getFilterTypeQuestions)
    case gameTypes.DELAY_TIME:
      return createQuestionsStructure(
        getDelayTimeQuestions,
        NUMBER_OF_QUESTIONS,
        delayTimes
      )
    case gameTypes.REVERB:
      return createQuestionsStructure(
        getReverbQuestions,
        NUMBER_OF_QUESTIONS,
        decayTimes
      )
    case gameTypes.DISTORTION:
      return createFixedNumberStructure(getDistortionQuestions)
    case gameTypes.COMPRESSOR_ATTACK:
      return createFixedNumberStructure(getCompressorAttackQuestions)
    case gameTypes.COMPRESSOR_RELEASE:
      return createFixedNumberStructure(getCompressorReleaseQuestions)
    case gameTypes.COMPRESSOR_RATIO:
      return createFixedNumberStructure(getCompressorRatioQuestions)
    default:
      throw new Error('Unknown game type')
  }
}

/*
 * requests to /api
 *
 * */

const axApi = axios.create({
  baseURL: `${apiURL}/api`,
  withCredentials: true,
})

/*
 * requests to /auth
 * */
const axUsers = axios.create({
  baseURL: `${apiURL}/auth`,
  timeout: 10000,
  withCredentials: true,
})

axiosRetry(axApi, { retries: 0 })

export const fetchTrack = (genre = 'default') => {
  // return load(`${soundsURL}${genre}.mp3`)
  return axios.get(`${soundsURL}${genre}.mp3`, {
    responseType: 'arraybuffer',
  })
}

export const fetchSprites = (genre = 'default') => {
  // const endpointURL =
  //   genre === 'default' ? 'sprite-data' : 'private/sprite-data'

  // return axApi.get(`/${endpointURL}`, {
  //   params: { genre },
  // })

  const spriteData =
    genre === 'default' ? getPublicSpriteData() : getPrivateSpriteData(genre)

  return Promise.resolve(spriteData)
}

export const fetchGameToken = (isLoggedIn) => {
  if (!isLoggedIn) {
    return Promise.resolve({ data: { gameToken: null } })
  }

  return axApi.get(`/private/game-token`)
}

export const fetchAllSounds = () => {
  // const sounds = load(`${soundsURL}sounds.mp3`)
  const sounds = axios.get(`${soundsURL}sounds.mp3`, {
    responseType: 'arraybuffer',
  })
  const track = fetchTrack()

  return Promise.all([sounds, track])
}

export const requestUpdateStats = (stats) => {
  return axApi.put(`/private/user/stats`, stats, {
    'axios-retry': {
      retries: 10,
      retryDelay: axiosRetry.exponentialDelay,
    },
  })
}

export const requestRank = (offset, limit) => {
  return axApi.get(`/private/users/rank`, {
    params: {
      offset,
      limit,
    },
  })
}

export const requestUserRank = (id) =>
  axApi.get(`/private/users/user-rank`, { params: { user_id: id } })

export const requestContact = (values) => {
  return axApi.post(`/contact`, values)
}

export const requestAvatarUploadUrl = () => {
  return axApi.get('private/user/avatar/uploadUrl')
}

export const requestAvatarUpload = (url, data) => {
  const formData = new FormData()

  for (let key in data) {
    formData.append(key, data[key])
  }

  return axApi.post(url, formData)
}

export const requestPublicProfile = (id) => {
  return axApi.get('/user/profile', { params: { id } })
}

export const requestAuth = (values) => {
  return axUsers.post('/login', values)
}

export const requestRegistration = (values) => {
  return axUsers.post('/register', values)
}

export const requestEmailConfirmation = (token) => {
  return axUsers.post(`/confirmation`, { token })
}

export const requestResendEmail = (values) => {
  return axUsers.post(`/resend`, values)
}

export const requestPasswordReset = (values) => {
  return axUsers.post(`/forgot`, values)
}

export const requestResetConfirmation = (token) => {
  return axUsers.post(`/reset-confirm/`, { token: token })
}

export const requestSetNewPassword = (values) => {
  return axUsers.post(`/password-change`, values)
}

export const requestCurrentUser = () => {
  return axUsers.get(`/auth-private/current-user`)
}

export const requestLogout = () => {
  return axUsers.post(`/auth-private/logout`)
}
