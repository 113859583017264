import * as t from 'constants/actionTypes'
import { points } from 'constants/settings'

const initialState = {
  show: false,
  position: 0
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.ANSWER:
      return processAnswer(payload, state)
    case t.NEXT_QUESTION:
      return {
        ...state,
        show: false
      }
    case t.LEVEL_COMPLETE:
      return {
        ...state,
        show: false
      }
    case t.GAME_OVER:
      return {
        ...state,
        show: false
      }
    case t.GAME_COMPLETE:
      return {
        ...state,
        show: false
      }
    default:
      return state
  }
}

const processAnswer = (payload, state) => {
  switch (payload.answerType) {
    case 'PERFECT':
      return {
        ...state,
        show: true,
        points: points.PERFECT_POINTS,
        result: 'Perfect!',
        position: payload.position
      }
    case 'GOOD':
      return {
        ...state,
        show: true,
        points: points.GOOD_POINTS,
        result: 'Good!',
        position: payload.position
      }
    default: {
      return {
        ...state,
        show: true,
        points: 0,
        result: 'Wrong :(',
        position: payload.position
      }
    }
  }
}
