export const FETCH_GAME_START = 'FETCH_GAME_START'
export const FETCH_GAME_SUCCESS = 'FETCH_GAME_SUCCESS'
export const FETCH_GAME_FAILURE = 'FETCH_GAME_FAILURE'

export const FETCH_TRACK_START = 'FETCH_TRACK_START'
export const FETCH_TRACK_SUCCESS = 'FETCH_TRACK_SUCCESS'
export const FETCH_TRACK_FAILURE = 'FETCH_TRACK_FAILURE'

//sound player actions
export const SWITCH_BYPASS = 'SWITCH_BYPASS'
export const SWITCH_MUTE = 'SWITCH_MUTE'

//game actions
export const GAME_START = 'GAME_START'
export const ANSWER = 'ANSWER'
export const NEXT_QUESTION = 'NEXT_QUESTION'
export const LEVEL_COMPLETE = 'LEVEL_COMPLETE'
export const NEXT_LEVEL = 'NEXT_LEVEL'
export const COUNT_BYPASS = 'COUNT_BYPASS'
export const GAME_OVER = 'GAME_OVER'
export const GAME_COMPLETE = 'GAME_COMPLETE'

//sound player
export const PLAYING_SOUND_END = 'PLAYING_SOUND_END'

//auth
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const LOGOUT = 'LOGOUT'

//registration
export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE'
export const EMAIL_CONFIRMATION_REQUEST = 'EMAIL_CONFIRMATION_REQUEST'
export const EMAIL_CONFIRMATION_SUCCESS = 'EMAIL_CONFIRMATION_SUCCESS'
export const EMAIL_CONFIRMATION_EXPIRED = 'EMAIL_CONFIRMATION_EXPIRED'
export const EMAIL_RESEND_REQUEST = 'EMAIL_RESEND_REQUEST'
export const EMAIL_RESEND_SUCCESS = 'EMAIL_RESEND_SUCCESS'
export const EMAIL_RESEND_FAILURE = 'EMAIL_RESEND_FAILURE'

//password reset
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST'
export const RESET_REQUEST_SUCCESS = 'RESET_REQUEST_SUCCESS'
export const RESET_REQUEST_FAILURE = 'RESET_REQUEST_FAILURE'
export const SEND_RESET_AGAIN = 'SEND_RESET_AGAIN'
export const RESET_CONFIRMATION_EXPIRED = 'RESET_CONFIRMATION_EXPIRED'
export const RESET_CONFIRMATION_SUCCESS = 'RESET_CONFIRMATION_SUCCESS'
export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST'
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS'
export const PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE'
export const INVALID_TOKEN = 'INVALID_TOKEN'

//CONTACT
export const SEND_CONTACT_REQUEST = 'SEND_CONTACT_REQUEST'
export const SEND_CONTACT_SUCCESS = 'SEND_CONTACT_SUCCESS'
export const SEND_CONTACT_FAILURE = 'SEND_CONTACT_FAILURE'

//ERRORS
export const ERROR = 'ERROR'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const FETCH_RANK_START = 'FETCH_RANK_START'
export const FETCH_RANK_SUCCESS = 'FECTH_RANK_SUCCESS'
export const INIT_RANK = 'INIT_RANK'

export const FETCH_USER_RANK_START = 'FETCH_USER_RANK_START'
export const FETCH_USER_RANK_SUCCESS = 'FETCH_USER_RANK_SUCCESS'

export const AVATAR_UPLOAD_REQUEST = 'AVATAR_UPLOAD_REQUEST'
export const AVATAR_UPLOAD_SUCCESS = 'AVATAR_UPLOAD_SUCCESS'
export const AVATAR_UPLOAD_FAILURE = 'AVATAR_UPLOAD_FAILURE'
export const AVATAR_CLEAR_INFO = 'AVATAR_CLEAR_INFO'

export const PUBLIC_PROFILE_REQUEST = 'PUBLIC_PROFILE_REQUEST'
export const PUBLIC_PROFILE_REQUEST_SUCCESS = 'PUBLIC_PROFILE_REQUEST_SUCCESS'
export const PUBLIC_PROFILE_REQUEST_FAILURE = 'PUBLIC_PROFILE_REQUEST_FAILURE'
