export default [
  0,
  3,
  5,
  10,
  15,
  20,
  25,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  120,
  140,
  160,
  180,
  200,
  220,
  240,
  280,
  320,
  360,
  400,
  450,
  500,
  550,
  600
]
