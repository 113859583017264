import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import history from './history'
import reducers from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

const configureStore = (initialState = {}) => {
  const middleware = applyMiddleware(thunk, routerMiddleware(history))

  const store = createStore(
    reducers(history),
    initialState,
    composeWithDevTools(middleware)
  )

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(reducers(history))
      })
    }
  }

  return store
}

export default configureStore
