import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './ErrorPage.module.scss'
import { clearErrors } from 'actions/errors'

const ErrorPage = ({ status, statusText, msg, clearErrors }) => {
  useEffect(() => () => clearErrors(), [clearErrors])

  return (
    <div className={styles.container}>
      <h1>Oops! {msg}</h1>
      <p>
        {status && `Status ${status}: `} {statusText}
      </p>
    </div>
  )
}

ErrorPage.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  statusText: PropTypes.string,
  msg: PropTypes.string,
  clearErrors: PropTypes.func.isRequired
}

export default connect(
  state => ({ ...state.error }),
  { clearErrors }
)(ErrorPage)
