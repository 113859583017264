import React from 'react'
import styles from './PanGrid.module.scss'

const PanGrid = () => {
  return (
    <div className={styles.container}>
      <div className={styles.segment_right}>80</div>
      <div className={styles.segment_right}>60</div>
      <div className={styles.segment_right}>40</div>
      <div className={styles.segment_right}>20</div>
      <div className={styles.segment_right} />
      <div className={styles.segment}>0</div>
      <div className={styles.segment}>20</div>
      <div className={styles.segment}>40</div>
      <div className={styles.segment}>60</div>
      <div className={styles.segment}>80</div>
    </div>
  )
}

export default PanGrid
