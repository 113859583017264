import React from 'react'
import styles from './Spectrum.module.scss'

function Spectrum() {
  return (
    <div className={styles.levels}>
      <div className={styles.level1}></div>
      <div className={styles.level2}></div>
      <div className={styles.level3}></div>
      <div className={styles.level4}></div>
      <div className={styles.level5}></div>
      <div className={styles.level6}></div>
      <div className={styles.level7}></div>
      <div className={styles.level8}></div>
      <div className={styles.level9}></div>
    </div>
  )
}

Spectrum.propTypes = {}

export default Spectrum
