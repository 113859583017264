import React from 'react'
import Game from 'modules/Game'
import * as gameTypes from 'constants/gameTypes'
import CompressorRelease from 'modules/Compressor/Compressor'

const GameCompressor = () => {
  return (
    <Game gameType={gameTypes.COMPRESSOR_RELEASE} withBubble={false}>
      {({ next, points }) => (
        <CompressorRelease
          next={next}
          points={points}
          key={'compressor_release'}
        />
      )}
    </Game>
  )
}

export default GameCompressor
