import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import HowlerToneReverb from './HowlerToneReverb'
import { connect } from 'react-redux'
import ControlPanel from 'components/ControlPanel'
import { getCurrentSprite } from 'reducers/sprites'
import { getCurrentQuestionValue } from 'reducers/questions'
import { getCurrentLevel, getCurrentQuestion } from 'reducers/game'
import DecayBg from './Decay_bg.png'
import { answer } from 'actions'
import decayTimes from 'constants/reverbTimeData'
import styles from './Reverb.module.scss'

class Reverb extends PureComponent {
  static propTypes = {
    points: PropTypes.number,
    answer: PropTypes.func.isRequired,
    decay: PropTypes.number.isRequired,
    playing: PropTypes.bool.isRequired,
    next: PropTypes.func.isRequired,
    show: PropTypes.bool,
    currentQuestion: PropTypes.number,
    result: PropTypes.string,
    wet: PropTypes.number.isRequired,
    src: PropTypes.string,
    bypass: PropTypes.bool,
    muted: PropTypes.bool,
    sprite: PropTypes.object
  }

  state = {
    answer: ''
  }

  onAnswer = ev => {
    if (this.state.answer !== '' || !this.props.playing) {
      return
    }

    const { value } = ev.target

    this.setState({
      answer: value
    })

    const answerIdx = decayTimes.indexOf(parseFloat(value))
    const actualIdx = decayTimes.indexOf(this.props.decay)
    const delta = Math.abs(answerIdx - actualIdx)

    if (delta === 0) {
      this.props.answer('PERFECT')
    } else if (delta <= 1) {
      this.props.answer('GOOD')
    } else {
      this.props.answer('FAIL')
    }

    this.props.next()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuestion !== this.props.currentQuestion) {
      this.setState({ answer: '' })
    }
  }

  renderBubble() {
    const { points, show, result, decay } = this.props

    const renderShow = points =>
      points ? styles.bubble_right : styles.bubble_wrong

    return (
      <div className={show ? renderShow(points) : styles.bubble}>
        <h1 className={styles.result}>{result}</h1>
        <p>Actual: {decay}</p>
        <p> + {points} points</p>
      </div>
    )
  }

  render() {
    const {
      decay,
      wet,
      src,
      sprite,
      playing,
      bypass,
      muted,
      points
    } = this.props
    return (
      <div>
        <HowlerToneReverb
          decay={decay}
          wet={wet}
          src={src}
          sprite={sprite}
          playing={playing}
          bypass={bypass}
          mute={muted}
        />
        <div className={styles.game_field}>
          <h2 className={styles.title}>
            Guess the reverb decay time in seconds
          </h2>
          <div className={styles.timeboxes_container}>
            {decayTimes.map(decay => {
              return (
                <div key={decay} className={styles.timebox_cell}>
                  <div className={styles.timebox}>
                    <input
                      id={decay}
                      name={decay}
                      type="checkbox"
                      checked={this.state.answer === decay.toString()}
                      onChange={this.onAnswer}
                      value={decay}
                    />
                    <label
                      htmlFor={decay}
                      className={points ? styles.right : styles.wrong}
                    >
                      {decay}
                    </label>
                  </div>
                </div>
              )
            })}
          </div>
          <p className={styles.wet_settings}>
            Wet: <strong>{wet * 100}%</strong>
          </p>
          <div className={styles.decay_bg}>
            <img src={DecayBg} alt="decay" />
          </div>
          {this.renderBubble()}
        </div>
        <ControlPanel bypassTextOn={'Reverb on'} bypassTextOff={'Reverb off'} />
        <p className={styles.tip}>
          Tip: for better results switch to a track with more distinct sounds
          e.g. one shots.
        </p>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const level = getCurrentLevel(state)
  return {
    level,
    currentQuestion: getCurrentQuestion(state),
    sprite: getCurrentSprite(state),
    decay: getCurrentQuestionValue(state),
    wet: (8 - (level - 1)) / 10,
    ...state.player,
    ...state.bubble
  }
}

export default connect(mapStateToProps, { answer })(Reverb)
