import { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Howl } from 'howler'
import sprite from 'constants/soundsSprite'
import { endPlayingSound } from 'actions/sounds'

class Sounds extends PureComponent {
  constructor(props) {
    super(props)

    this.onSoundEnd = this.onSoundEnd.bind(this)
  }

  static propTypes = {
    src: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]).isRequired,
    play: PropTypes.bool.isRequired,
    slice: PropTypes.string.isRequired,
    endPlayingSound: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.initHowler()
  }

  componentDidUpdate(prevProps) {
    if (this.props.play && !prevProps.play) {
      this.play(this.props.slice)
    }
  }

  componentWillUnmount() {
    this.destroyHowler()
  }

  initHowler(props = this.props) {
    this.destroyHowler()

    if (typeof Howl !== 'undefined') { // Check if window is available
      this.howl = new Howl({
        src: props.src,
        sprite,
        onend: this.onSoundEnd
      })
    }
  }

  destroyHowler() {
    if (this.howl) {
      this.howl.off() // Remove event listener
      this.howl.stop() // Stop playback
      this.howl.unload() // Remove sound from pool
      this.howl = null // Destroy it
    }
  }

  play(slice) {
    const playing = this.howl.playing()

    if (!playing) {
      // Automatically load if we're trying to play
      // and the howl is not loaded
      if (this.howl.state() === 'unloaded') {
        this.howl.load()
      }
      this.howl.play(slice)
    }
  }

  onSoundEnd() {
    this.props.endPlayingSound()
  }

  render() {
    return null
  }
}

export default connect(null, { endPlayingSound })(Sounds)
