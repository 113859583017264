import React from 'react'
import { LEVELS_TOTAL } from 'constants/settings'
import styles from './GameStatusBar.module.scss'
import { ReactComponent as IconLive } from './icons/IconLive.svg'
import { ReactComponent as IconDeath } from './icons/IconDeath.svg'

import PropTypes from 'prop-types'

const GameStatusBar = ({ livesTotal, livesLeft, score, level }) => {
  const renderLives = (livesTotal, livesLeft) => {
    const livesEmpty = new Array(livesTotal - livesLeft).fill(false)
    const livesFull = new Array(livesLeft).fill(true)

    return [...livesEmpty, ...livesFull].map((value, idx) => (
      <div key={idx} className={styles.icon}>
        {value ? <IconLive /> : <IconDeath />}
      </div>
    ))
  }

  return (
    <div className={styles.container}>
      <span className={styles.level}>
        Level: {level} / {LEVELS_TOTAL}
      </span>
      <span>Score: {score}</span>
      <div className={styles.lives_container}>
        {renderLives(livesTotal, livesLeft)}
      </div>
    </div>
  )
}

export default GameStatusBar

GameStatusBar.propTypes = {
  level: PropTypes.number.isRequired,
  livesLeft: PropTypes.number.isRequired,
  livesTotal: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired
}
