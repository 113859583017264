import { ERROR } from 'constants/actionTypes'
import { replace } from 'connected-react-router'
import { LOGOUT } from 'constants/actionTypes'
import { get } from 'lodash'

export const errorHandler = (err, dispatch) => {
  const status = get(err, 'response.status')

  if (!status) {
    return dispatch({
      type: ERROR,
      payload: {
        msg: `Network error. Check your internet connection and try again later`
      }
    })
  }
  //eslint-disable-next-line
  if (status == 401) {
    dispatch({
      type: LOGOUT
    })
    return dispatch(replace('/login'))
  }

  return dispatch({
    type: ERROR,
    payload: {
      msg: `Something went wrong. Please try again later.`
    }
  })
}
