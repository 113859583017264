import {
  PUBLIC_PROFILE_REQUEST,
  PUBLIC_PROFILE_REQUEST_SUCCESS,
  PUBLIC_PROFILE_REQUEST_FAILURE
} from 'constants/actionTypes'

const initialState = {
  isLoading: false,
  user: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PUBLIC_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case PUBLIC_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: payload
      }
    case PUBLIC_PROFILE_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
