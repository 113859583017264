import React from 'react'
import Game from 'modules/Game'
import Distortion from 'modules/Distortion'
import * as gameTypes from 'constants/gameTypes'

const GameDelayTime = () => {
  return (
    <Game gameType={gameTypes.DISTORTION} withBubble={false}>
      {({ next, points }) => (
        <Distortion next={next} points={points} key={gameTypes.DISTORTION} />
      )}
    </Game>
  )
}

export default GameDelayTime
