import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as LowPass } from './LowPass.svg'
import { ReactComponent as HighPass } from './HighPass.svg'
import { ReactComponent as BandPass } from './BandPass.svg'
import { ReactComponent as AllPass } from './AllPass.svg'
import { ReactComponent as LowShelf } from './LowShelf.svg'
import { ReactComponent as HighShelf } from './HighShelf.svg'
import { ReactComponent as Peaking } from './Peaking.svg'
import { ReactComponent as Notch } from './Notch.svg'

import styles from './FilterTypeDisplay.module.scss'
import PropTypes from 'prop-types'

export class FilterTypeDisplay extends PureComponent {
  static propTypes = {
    onAnswer: PropTypes.func.isRequired,
    points: PropTypes.number,
    actual: PropTypes.string,
    currentQuestion: PropTypes.number.isRequired
  }

  filterTypes = [
    'High pass',
    'Low pass',
    'Band pass',
    'Peaking',
    'Notch',
    'Low shelf',
    'High shelf',
    'All pass'
  ]

  state = { answer: '' }

  handleAnswer = e => {
    if (this.state.answer !== '') {
      return
    }

    const { value } = e.target

    this.setState({ answer: value })

    this.props.onAnswer(value)
  }

  renderBubble() {
    const { points, actual } = this.props

    if (points > 0) {
      return (
        <div className={styles.bubble_container}>
          <h1 className={styles.bubble_title}>Right!</h1>
          <p>Points: +{points} </p>
        </div>
      )
    }

    return (
      <div className={styles.bubble_container}>
        <h1 className={styles.bubble_title}>Wrong :(</h1>
        <p>Actual: {actual} </p>
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuestion !== this.props.currentQuestion) {
      this.setState({ answer: '' })
    }
  }

  bg = {
    lowpass: <LowPass className={styles.bg} />,
    highpass: <HighPass className={styles.bg} />,
    bandpass: <BandPass className={styles.bg} />,
    allpass: <AllPass className={styles.bg} />,
    notch: <Notch className={styles.bg} />,
    lowshelf: <LowShelf className={styles.bg} />,
    highshelf: <HighShelf className={styles.bg} />,
    peaking: <Peaking className={styles.bg} />
  }

  render() {
    const { points } = this.props

    return (
      <div className={styles.container}>
        {this.filterTypes.map(name => {
          const filterType = name
            .split(' ')
            .join('')
            .toLowerCase()

          const checked = this.state.answer === filterType

          return (
            <div className={styles.filter_container} key={filterType}>
              <input
                type="checkbox"
                id={filterType}
                value={filterType}
                checked={checked}
                onChange={this.handleAnswer}
              />
              <label
                className={checked && points > 0 ? styles.right : styles.wrong}
                htmlFor={filterType}
              >
                {!checked && <span>{name.toUpperCase()}</span>}
                {checked && this.renderBubble()}
                {this.bg[filterType]}
              </label>
            </div>
          )
        })}
      </div>
    )
  }
}

export default connect(state => ({ ...state.bubble }))(FilterTypeDisplay)
