import React, { PureComponent } from 'react'
import Quize from 'components/Quize'
import HowlerDb from './HowlerTunaDb'
import ControlPanel from 'components/ControlPanel'
import Random from 'random-js'
import { connect } from 'react-redux'
import { answer } from 'actions'
import { getCurrentSprite } from 'reducers/sprites'
import { getCurrentQuestionValue } from 'reducers/questions'
import { getCurrentLevel, getCurrentQuestion } from 'reducers/game'
import { ReactComponent as Speaker } from './Speaker.svg'
import { gainToDb } from './utils'
import PropTypes from 'prop-types'

export class DbQuize extends PureComponent {
  constructor(props) {
    super(props)

    this.random = new Random(Random.engines.mt19937().autoSeed())

    this.state = this.populateStateWithRandoms()
  }

  static propTypes = {
    gain: PropTypes.number,
    playing: PropTypes.bool.isRequired,
    answer: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    sprite: PropTypes.object,
    bypass: PropTypes.bool.isRequired,
    muted: PropTypes.bool.isRequired,
    points: PropTypes.number,
    currentQuestion: PropTypes.number.isRequired,
  }

  populateStateWithRandoms() {
    const dic = {}
    const randoms = new Set()
    const actual = gainToDb(this.props.gain)

    while (randoms.size !== 4) {
      const num =
        actual >= 0
          ? this.random.integer(0, 60) / 10
          : this.random.integer(-200, 0) / 10
      //prevent having number the same as actual in the boilerplate dic to avoid dublicates when
      //we will have assigned actual number to a random later
      if (num !== actual) {
        const val = num > 0 ? `+${num} dB` : `${num} dB`

        randoms.add(val)
      }
    }

    //destructuring set with unique values to dic { a: value, b: value, etc}
    ;[dic.a, dic.b, dic.c, dic.d] = [...randoms]

    //choose random key to assign actual gain value to it
    const key = this.random.pick(['a', 'b', 'c', 'd'])

    dic[key] = actual > 0 ? `+${actual} dB` : `${actual} dB`

    return dic
  }

  onAnswer = (answer) => {
    //don't trigger answer if track isn't playing
    if (!this.props.playing) {
      return
    }

    const actual = gainToDb(this.props.gain)

    if (answer === actual) {
      this.props.answer('GOOD')
    } else {
      this.props.answer('FAIL')
    }

    this.props.next()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuestion !== this.props.currentQuestion) {
      const newState = this.populateStateWithRandoms()

      this.setState(newState)
    }
  }

  render() {
    const { gain, src, sprite, playing, bypass, muted, points } = this.props
    const actual = gainToDb(gain)
    const actualString = actual > 0 ? `+${actual} dB` : `${actual} dB`

    return (
      <div>
        <HowlerDb
          gain={gain}
          src={src}
          sprite={sprite}
          playing={playing}
          bypass={bypass}
          mute={muted}
        />
        <Quize
          onAnswer={this.onAnswer}
          data={this.state}
          actual={actualString}
          bg={Speaker}
          points={points}
        />
        <ControlPanel
          bypassTextOn={'Gain change on'}
          bypassTextOff={'Gain change off'}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    level: getCurrentLevel(state),
    currentQuestion: getCurrentQuestion(state),
    sprite: getCurrentSprite(state),
    gain: getCurrentQuestionValue(state),
    ...state.player,
  }
}

export default connect(mapStateToProps, { answer })(DbQuize)
