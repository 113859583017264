import {
  AVATAR_UPLOAD_REQUEST,
  AVATAR_UPLOAD_SUCCESS,
  AVATAR_UPLOAD_FAILURE,
  AVATAR_CLEAR_INFO
} from 'constants/actionTypes'

const initialState = {
  isLoading: false,
  error: false,
  message: ''
}

export default (state = initialState, { type }) => {
  switch (type) {
    case AVATAR_UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: ''
      }
    case AVATAR_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        message: 'Your avatar has been successfully updated!'
      }
    case AVATAR_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: 'Something went wrong, please try again later'
      }
    case AVATAR_CLEAR_INFO:
      return {
        ...state,
        error: false,
        message: ''
      }

    default:
      return state
  }
}
