import React, { PureComponent } from 'react'
import Switch from '../Switch'
import MuteButton from '../MuteButton'
import styles from './ControlPanel.module.scss'
import { switchBypass, switchMute, countBypass } from 'actions'
import { connect } from 'react-redux'
import { throttle } from 'lodash'
import PropTypes from 'prop-types'

class ControlPanel extends PureComponent {
  constructor(props) {
    super(props)

    this.switchMute = this.switchMute.bind(this)
    this.switchBypass = this.switchBypass.bind(this)
    this.switchBypassThrottled = throttle(this.switchBypass, 300, {
      leading: true,
      trailing: false
    })
  }

  static propTypes = {
    switchBypass: PropTypes.func.isRequired,
    switchMute: PropTypes.func.isRequired,
    bypassTextOn: PropTypes.string.isRequired,
    bypassTextOff: PropTypes.string.isRequired,
    bypassCount: PropTypes.number,
    bypass: PropTypes.bool.isRequired,
    muted: PropTypes.bool.isRequired,
    countBypass: PropTypes.func.isRequired
  }

  switchBypass() {
    if (
      this.props.bypassCount > 0 ||
      (this.props.bypassCount === 0 && this.props.bypass)
    ) {
      if (!this.props.bypass) {
        this.props.countBypass()
      }

      this.props.switchBypass(!this.props.bypass)
    }
  }

  switchMute() {
    this.props.switchMute(!this.props.muted)
  }

  render() {
    const { bypassTextOn, bypassTextOff, muted, bypassCount } = this.props
    const isActive = bypassCount !== 0

    return (
      <div className={styles.container}>
        <Switch
          nameOn={bypassTextOn}
          nameOff={bypassTextOff}
          isOn={!this.props.bypass}
          onChange={this.switchBypassThrottled}
          isActive={isActive}
          bypassCount={bypassCount}
        />
        <div className={styles.mute_button}>
          <MuteButton muted={muted} onMute={this.switchMute} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { bypass, muted } = state.player

  return {
    bypass,
    muted,
    bypassCount: state.game.bypassCount
  }
}

export default connect(mapStateToProps, {
  switchBypass,
  switchMute,
  countBypass
})(ControlPanel)
