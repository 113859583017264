import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import './styles-common/transitions.css'
import './styles-common/animations.css'
import './index.module.scss'

import App from './App'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { from } from 'apollo-link'
import { onError } from 'apollo-link-error'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'

import { ConnectedRouter } from 'connected-react-router'

import history from './history'
import configureStore from './configureStore'

import { AUTH_FAILURE, AUTH_SUCCESS, ERROR } from './constants/actionTypes'

import { requestCurrentUser } from './api'

import get from 'lodash/get'
import { errorHandler } from 'utils/errorHandler'

import WebFont from 'webfontloader'
import Analytics from 'react-router-ga'

window.process = {}

WebFont.load({
  google: {
    // families: ['Open Sans:300,400', 'sans-serif']
    families: ['Titillium Web:300,400,700', 'sans-serif'],
  },
  timeout: 5000,
})

const store = configureStore()
const rootElement = document.getElementById('root')

async function getCurrentUser() {
  try {
    const res = await requestCurrentUser()
    store.dispatch({
      type: AUTH_SUCCESS,
      payload: res.data.user,
    })
  } catch (err) {
    const status = get(err, 'response.status')

    if (status === 401) {
      store.dispatch({
        type: AUTH_FAILURE,
        payload: 'Token is missing or expired',
      })
    } else {
      store.dispatch({
        type: AUTH_FAILURE,
      })

      errorHandler(err, store.dispatch)
    }
  }
}

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL || ''}/graphql`,
  credentials: 'include',
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions.code === 'UNAUTHENTICATED') {
        store.dispatch({
          type: AUTH_FAILURE,
          payload: 'Token is missing or expired',
        })
      }
    }
  }

  if (networkError) {
    store.dispatch({
      type: ERROR,
      payload: {
        msg: 'Something went wrong. Check your internet connection and try again later.',
      },
    })
  }
})

const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
})

function render(Component) {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div>
            <Component />
          </div>
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>,
    rootElement
  )
}

render(App)

// getCurrentUser().then(() => render(App))

if (module.hot) {
  module.hot.accept('./App', () => {
    render(App)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
