import React from 'react'
import Game from 'modules/Game'
import * as gameTypes from 'constants/gameTypes'
import CompressorRatio from 'modules/Compressor/Compressor'

const GameCompressor = () => {
  return (
    <Game gameType={gameTypes.COMPRESSOR_RATIO} withBubble={false}>
      {({ next, points }) => (
        <CompressorRatio
          next={next}
          points={points}
          key={'compressor_ratio'}
        />
      )}
    </Game>
  )
}

export default GameCompressor
