import {
  SEND_CONTACT_REQUEST,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAILURE
} from 'constants/actionTypes'

import { SubmissionError } from 'redux-form'
import { errorHandler } from 'utils/errorHandler'
import { requestContact } from '../api'
import { reset } from 'redux-form'
import { get } from 'lodash'

export const sendContactMail = values => async dispatch => {
  try {
    dispatch({
      type: SEND_CONTACT_REQUEST
    })

    await requestContact(values)

    dispatch({
      type: SEND_CONTACT_SUCCESS
    })

    dispatch(reset('contactForm'))
  } catch (err) {
    dispatch({
      type: SEND_CONTACT_FAILURE
    })
    const status = get(err, 'response.status')

    if (status === 421) {
      throw new SubmissionError({
        _error: 'Please check the captcha and try again'
      })
    } else {
      errorHandler(err, dispatch)
    }
  }
}
