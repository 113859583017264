import React from 'react'
import styles from './LoadingGame.module.scss'

const LoadingGame = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        <div className={styles.one} />
        <div className={styles.two} />
        <div className={styles.three} />
        <div className={styles.four} />
        <div className={styles.five} />
        <div className={styles.six} />
        <div className={styles.seven} />
        <div className={styles.eight} />
        <div className={styles.nine} />
        <div className={styles.ten} />
      </div>
      <span className={styles.title}>Loading...</span>
    </div>
  )
}

export default LoadingGame
