import React from 'react'
import Game from 'modules/Game'
import Reverb from 'modules/Reverb'
import * as gameTypes from 'constants/gameTypes'

const GameReverb = () => {
  return (
    <Game gameType={gameTypes.REVERB} withBubble={false}>
      {({ next }) => <Reverb next={next} key={'reverb'} />}
    </Game>
  )
}

export default GameReverb
