import { shuffle } from 'lodash'

const publicSpriteSrcData = [
  [117, 15469],
  [15727, 15972],
  [31855, 16003],
  [48104, 17129],
  [65540, 17447],
  [83588, 16000],
]

const privateSpriteSrcData = {
  whitenoise: [[50, 3000]],
  pinknoise: [[50, 3000]],
  house: [
    [51, 3749],
    [3852, 8000],
    [11914, 7742],
    [19802, 7743],
    [27603, 8224],
    [35849, 3753],
    [39664, 4014],
    [43727, 8003],
    [51811, 7677],
    [59551, 7381],
  ],
  futurebass: [
    [102, 11950],
    [12164, 12722],
    [24977, 9580],
    [34680, 12804],
    [47539, 7994],
    [55629, 10206],
    [65914, 13233],
    [79226, 6197],
    [85587, 5647],
  ],
  synthwave: [
    [103, 9226],
    [9413, 8138],
    [17601, 11427],
    [29101, 10661],
    [39851, 18287],
    [58289, 4794],
    [63288, 15998],
    [79601, 12001],
  ],
  acoustic: [
    [102, 9579],
    [9916, 9060],
    [19105, 9587],
    [28914, 8900],
    [38102, 9225],
    [47602, 11935],
    [59851, 17965],
    [78102, 9579],
  ],
  drumloops: [
    [400, 1851],
    [2443, 3434],
    [6248, 3416],
    [9834, 3693],
    [13909, 3430],
    [17557, 3430],
    [21362, 1730],
  ],
  piano: [
    [102, 4177],
    [4540, 24154],
    [28920, 8712],
    [37790, 8728],
    [46665, 10593],
    [57418, 20427],
    [78040, 9142],
    [87290, 11991],
  ],
  rock: [
    [103, 6104],
    [6227, 5312],
    [11564, 6864],
    [18472, 10002],
    [28528, 4715],
    [33540, 7113],
    [40711, 9410],
    [50227, 12303],
  ],
  metal: [
    [71, 9105],
    [9227, 10124],
    [19416, 2890],
    [22352, 10445],
    [32853, 2900],
    [35789, 7386],
    [43226, 5050],
    [48352, 5329],
  ],
  trap: [
    [120, 14779],
    [15039, 14211],
    [29332, 13601],
    [42964, 13241],
    [56289, 10269],
    [66608, 14769],
    [81550, 13708],
    [95414, 13713],
  ],
  oneshots: [
    [40, 850],
    [897, 850],
    [1755, 850],
    [2611, 850],
    [3468, 850],
    [4320, 850],
    [5183, 850],
    [6039, 850],
    [6897, 850],
    [7755, 850],
    [8611, 850],
  ],
}

export const getPublicSpriteData = () => {
  const shuffledSrcData = shuffle(publicSpriteSrcData)

  const sprite = shuffledSrcData.reduce((acc, cur, i) => {
    return { ...acc, ['track_' + (i + 1)]: cur }
  }, {})

  return { sprite }
}

export const getPrivateSpriteData = (genre) => {
  const spriteSrcData = privateSpriteSrcData[genre]

  const shuffledSrcData = shuffle(spriteSrcData)
  // const shuffledSrcData = spriteSrcData //debug only

  let sprite = spriteSrcData

  if (genre !== 'whitenoise' && genre !== 'pinknoise') {
    sprite = shuffledSrcData.reduce((acc, cur, i) => {
      return { ...acc, ['track_' + (i + 1)]: cur }
    }, {})
  }
  return { sprite }
}
