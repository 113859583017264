import {
  FETCH_GAME_START,
  FETCH_GAME_FAILURE,
  FETCH_GAME_SUCCESS,
  FETCH_TRACK_SUCCESS,
  FETCH_TRACK_START,
  FETCH_TRACK_FAILURE,
  FETCH_RANK_START,
  FETCH_RANK_SUCCESS,
  FETCH_USER_RANK_START,
  FETCH_USER_RANK_SUCCESS,
  INIT_RANK,
  PUBLIC_PROFILE_REQUEST,
  PUBLIC_PROFILE_REQUEST_SUCCESS,
  PUBLIC_PROFILE_REQUEST_FAILURE,
} from '../constants/actionTypes'

import {
  fetchAllSounds,
  fetchTrack,
  fetchSprites,
  fetchGameToken,
  requestRank,
  requestUserRank,
  requestPublicProfile,
} from '../api'
import { errorHandler } from '../utils/errorHandler'

export const fetchGame = () => async (dispatch, getState) => {
  const { isLoggedIn } = getState().auth
  const { genre } = getState().game

  dispatch({
    type: FETCH_GAME_START,
  })

  try {
    const response = await Promise.all([
      fetchSprites(genre),
      fetchGameToken(isLoggedIn),
      fetchAllSounds(),
    ])

    const { sprite } = response[0]
    const { gameToken } = response[1].data

    dispatch({
      type: FETCH_GAME_SUCCESS,
      payload: { sprite, gameToken },
    })
  } catch (err) {
    dispatch({
      type: FETCH_GAME_FAILURE,
      payload: err,
      error: true,
    })
  }
}

export const switchTrack = (genre) => async (dispatch) => {
  dispatch({
    type: FETCH_TRACK_START,
    payload: genre,
  })

  try {
    const response = await Promise.all([fetchSprites(genre), fetchTrack(genre)])
    // const { sprite } = response[0].data
    const { sprite } = response[0]

    dispatch({
      type: FETCH_TRACK_SUCCESS,
      payload: { genre, sprite },
    })
  } catch (err) {
    dispatch({
      type: FETCH_TRACK_FAILURE,
      payload: err,
      error: true,
    })
  }
}

export const getRank = (offset, limit) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_RANK_START })

    const res = await requestRank(offset, limit)

    dispatch({
      type: FETCH_RANK_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    errorHandler(err, dispatch)
  }
}

export const getUserRank = (id) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USER_RANK_START })

    const res = await requestUserRank(id)

    dispatch({
      type: FETCH_USER_RANK_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    errorHandler(err, dispatch)
  }
}

export const initRank = () => ({ type: INIT_RANK })

export const getPublicUser = (id) => async (dispatch) => {
  dispatch({
    type: PUBLIC_PROFILE_REQUEST,
  })

  try {
    const response = await requestPublicProfile(id)

    dispatch({
      type: PUBLIC_PROFILE_REQUEST_SUCCESS,
      payload: response.data,
    })
  } catch (err) {
    dispatch({
      type: PUBLIC_PROFILE_REQUEST_FAILURE,
    })
    errorHandler(err, dispatch)
  }
}
