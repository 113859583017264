import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import styles from './Start.module.scss'

function Start({ onClick }) {
  return (
    <div className={styles.container}>
      <div className={styles.pulse}>
        <Button onClick={onClick}>Start</Button>
      </div>
    </div>
  )
}

Start.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default Start
