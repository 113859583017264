import {
  FETCH_RANK_START,
  FETCH_RANK_SUCCESS,
  // FETCH_USER_RANK_START,
  FETCH_USER_RANK_SUCCESS
} from 'constants/actionTypes'
import { INIT_RANK } from '../constants/actionTypes'
import { PER_PAGE } from 'constants/settings'

const initialState = {
  isFetching: false,
  isAllFetched: false,
  myRank: null,
  data: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RANK_START:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_USER_RANK_SUCCESS:
      return {
        ...state,
        myRank: payload.rank
      }
    case FETCH_RANK_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: [...state.data, ...payload],
        isAllFetched: payload.length < PER_PAGE
      }
    }
    case INIT_RANK:
      return initialState
    default:
      return state
  }
}

export const isFetching = state => state.rank.isFetching
export const getData = state => state.rank.data
export const getMyRank = state => {
  const idx = state.rank.data.findIndex(user => user.id === state.auth.user.id)

  return idx !== -1 ? idx + 1 : state.rank.myRank
}

export const getIsAllFetched = state => state.rank.isAllFetched
