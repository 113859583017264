import React from 'react'
import styles from './InputField.module.scss'
import PropTypes from 'prop-types'

const InputField = ({
  textarea,
  input,
  label,
  labelLink,
  placeholder,
  autoComplete = 'on',
  type,
  tabIndex,
  meta: { touched, error }
}) => (
  <div className={styles.input_container}>
    <label className={styles.label}>
      {label}
      {labelLink}
    </label>
    <div>
      {textarea ? (
        <textarea
          className={styles.textArea}
          rows="10"
          maxLength="3000"
          tabIndex={tabIndex}
          placeholder={placeholder}
          {...input}
        />
      ) : (
        <input
          className={styles.input}
          autoComplete={autoComplete}
          tabIndex={tabIndex}
          placeholder={placeholder}
          type={type}
          {...input}
        />
      )}
      {touched && error && <span className={styles.error_field}>{error}</span>}
    </div>
  </div>
)

InputField.propTypes = {
  input: PropTypes.object.isRequired,
  textarea: PropTypes.bool,
  label: PropTypes.string,
  labelLink: PropTypes.element,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  tabIndex: PropTypes.string,
  autoComplete: PropTypes.string,
  meta: PropTypes.object.isRequired
}

export default InputField
