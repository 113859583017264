import React from 'react'
import { ReactComponent as Icon } from './icons/IconNewLevel.svg'
import styles from './LevelComplete.module.scss'

const LevelComplete = () => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Icon />
      </div>
      <h1 className={styles.anim}>Level complete!</h1>
    </div>
  )
}

export default LevelComplete
