import { PureComponent } from 'react'
import Tuna from 'tunajs'
import PropTypes from 'prop-types'
import withHowler from 'components/HowlerTuna/'

class TunaEq extends PureComponent {
  static propTypes = {
    //Filter props
    frequency: PropTypes.number, //20 to 22050
    Q: PropTypes.number, //0.001 to 100
    gain: PropTypes.number, //-40 to 40 (in decibels)
    filterType: PropTypes.string, //lowpass, highpass, bandpass, lowshelf, highshelf, peaking, notch, allpass
    bypass: PropTypes.bool,

    howl: PropTypes.object,
    howler: PropTypes.object.isRequired
  }

  static defaultProps = {
    //Filter props
    frequency: 1000, //20 to 22050
    Q: 4, //0.001 to 100
    gain: 12, //-40 to 40 (in decibels)
    filterType: 'peaking', //lowpass, highpass, bandpass, lowshelf, highshelf, peaking, notch, allpass
    bypass: false
  }

  componentDidUpdate(prevProps) {
    const { frequency, bypass, howl } = this.props

    if (howl && prevProps.howl !== this.props.howl) {
      this.initFilter()
    }

    if (frequency !== prevProps.frequency) {
      this.updateFreq(frequency)
    }

    if (bypass !== prevProps.bypass) {
      this.updateBypass(bypass)
    }
  }

  initFilter() {
    const { frequency, Q, gain, filterType, bypass } = this.props

    const ctx = this.props.howler.ctx
    const tuna = new Tuna(ctx)
    const howlNode = this.props.howl._sounds[0]._node

    this.filter = new tuna.Filter({
      frequency, //20 to 22050
      Q, //0.001 to 100
      gain, //-40 to 40 (in decibels)
      filterType, //lowpass, highpass, bandpass, lowshelf, highshelf, peaking, notch, allpass
      bypass
    })

    howlNode.disconnect(this.props.howler.masterGain)
    howlNode.connect(this.filter)
    this.filter.connect(this.props.howler.masterGain)
  }

  updateFreq(freq) {
    this.filter.frequency = freq
  }

  updateBypass(bypass) {
    this.filter.bypass = bypass
  }

  render() {
    return null
  }
}

export default withHowler(TunaEq)
