export default [
  {
    title: 'Default',
    id: 'default'
  },
  {
    title: 'House',
    id: 'house'
  },
  {
    title: 'Future bass',
    id: 'futurebass'
  },
  {
    title: 'Trap',
    id: 'trap'
  },
  {
    title: 'Synthwave',
    id: 'synthwave'
  },
  {
    title: 'Acoustic Guitar',
    id: 'acoustic'
  },
  {
    title: 'Rock',
    id: 'rock'
  },
  {
    title: 'Metal',
    id: 'metal'
  },
  {
    title: 'Piano',
    id: 'piano'
  },
  {
    title: 'Drum loops',
    id: 'drumloops'
  },
  {
    title: 'One shots',
    id: 'oneshots'
  },
  {
    title: 'Pink noise',
    id: 'pinknoise'
  },
  {
    title: 'White noise',
    id: 'whitenoise'
  }
]
