import React from 'react'
import Game from 'modules/Game'
import * as gameTypes from 'constants/gameTypes'
import { DbPro } from 'modules/Db'

const GameDbPro = () => {
  return (
    <Game gameType={gameTypes.DB_PRO} withBubble={false}>
      {({ next, points }) => (
        <DbPro next={next} points={points} key={'db_pro'} />
      )}
    </Game>
  )
}

export default GameDbPro
