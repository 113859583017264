import { GAME_START } from '../constants/actionTypes'
import { path } from 'ramda'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = {}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GAME_START:
      return payload.questions
    case LOCATION_CHANGE:
      return initialState
    default:
      return state
  }
}

export const getCurrentQuestionValue = state => {
  const questionNumber = path(['game', 'currentQuestion'], state)

  return path([questionNumber, 'value'], state.questions)
}

export const getCurrentQuestionText = state => {
  const questionNumber = path(['game', 'currentQuestion'], state)

  return path([questionNumber, 'text'], state.questions)
}

export const getCurrentQuestionType = state => {
  const questionNumber = path(['game', 'currentQuestion'], state)

  return path([questionNumber, 'type'], state.questions)
}

export const getCurrentQuestionValueWithText = state => {
  const questionNumber = path(['game', 'currentQuestion'], state)

  return (
    Math.abs(path([questionNumber, 'value'], state.questions)) +
    ' ' +
    (path([questionNumber, 'text'], state.questions) || '')
  )
}
