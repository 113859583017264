import React, { useRef } from 'react'
import { Field, reduxForm } from 'redux-form'
import FormButton from 'components/FormButton'
import InputField from 'components/InputField'
import { sendContactMail } from 'actions/contact'
import { connect } from 'react-redux'
import validator from 'validator'
import ReCAPTCHA from 'react-google-recaptcha'

import styles from './Contact.module.scss'

import PropTypes from 'prop-types'

const validate = ({ email, name, message }) => {
  const errors = {}

  if (!email) {
    errors.email = 'Please enter your email address'
  } else if (!validator.isEmail(email)) {
    errors.email = 'Invalid email format'
  }

  if (!name) {
    errors.name = 'Please enter your name'
  }

  if (!message) {
    errors.message = 'We can`t help you if your message is going to be empty'
  }

  if (message && !validator.isLength(message, { min: 10, max: 3000 })) {
    errors.message = 'The message should be between 10 and 3000 symbols length'
  }

  return errors
}

function Contact({
  submitting,
  pristine,
  invalid,
  error,
  handleSubmit,
  sendContactMail,
  clearSubmitErrors
}) {
  const normalizeEmail = value => value && value.toLowerCase().trim()
  const recaptchaRef = useRef(null)
  const ReCAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY

  const submit = values => {
    const recaptchaValue = recaptchaRef.current.getValue()
    return sendContactMail({ ...values, recaptchaValue })
  }

  const onCaptchaChange = () => clearSubmitErrors({ form: 'contactForm' })

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Leave us a message</h1>
      <form onSubmit={handleSubmit(submit)}>
        <Field
          name="email"
          type="text"
          tabIndex="1"
          component={InputField}
          label="Your email *"
          normalize={normalizeEmail}
        />

        <Field
          name="name"
          type="text"
          tabIndex="2"
          component={InputField}
          label="Your name *"
        />

        <Field
          name="message"
          type="text"
          tabIndex="3"
          textarea={true}
          component={InputField}
          label="What's on your mind? *"
        />

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={ReCAPTCHA_KEY}
          theme="dark"
          tabIndex="4"
          onChange={onCaptchaChange}
        />
        {error && <div className={styles.error}>{error}</div>}

        <div className={styles.submit}>
          <FormButton
            type="submit"
            disabled={invalid || pristine || submitting}
            loading={false}
          >
            Send
          </FormButton>
        </div>
      </form>
    </div>
  )
}

Contact.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  sendContactMail: PropTypes.func.isRequired,
  clearSubmitErrors: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  error: PropTypes.string
}

export default connect(null, { sendContactMail })(
  reduxForm({
    form: 'contactForm',
    validate
  })(Contact)
)
