import React, { Component } from 'react'
import styles from './DelayTime.module.scss'
import { connect } from 'react-redux'
import ControlPanel from 'components/ControlPanel'
import { getCurrentSprite } from 'reducers/sprites'
import { getCurrentQuestionValue } from 'reducers/questions'
import { answer } from 'actions'

import { getCurrentLevel, getCurrentQuestion } from 'reducers/game'
import delayTimes from 'constants/delayTimeData'
import HowlerTunaDelay from './HowlerTunaDelay'
import PropTypes from 'prop-types'

class DelayTime extends Component {
  static propTypes = {
    playing: PropTypes.bool.isRequired,
    answer: PropTypes.func.isRequired,
    delayTime: PropTypes.number,
    next: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    sprite: PropTypes.object,
    bypass: PropTypes.bool.isRequired,
    muted: PropTypes.bool.isRequired,
    currentQuestion: PropTypes.number.isRequired
  }

  state = {
    answer: ''
  }

  onAnswer = ev => {
    if (this.state.answer !== '' || !this.props.playing) {
      return
    }

    const { value } = ev.target

    this.setState({
      answer: value
    })

    const answerIdx = delayTimes.indexOf(parseInt(value))
    const actualIdx = delayTimes.indexOf(this.props.delayTime)
    const delta = Math.abs(answerIdx - actualIdx)

    if (delta === 0) {
      this.props.answer('PERFECT')
    } else if (delta <= 2) {
      this.props.answer('GOOD')
    } else {
      this.props.answer('FAIL')
    }

    this.props.next()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuestion !== this.props.currentQuestion) {
      this.setState({ answer: '' })
    }
  }

  renderBubble() {
    const { points, show, result, delayTime } = this.props

    const renderShow = points =>
      points ? styles.bubble_right : styles.bubble_wrong

    return (
      <div className={show ? renderShow(points) : styles.bubble}>
        <h1 className={styles.result}>{result}</h1>
        <p>Actual: {delayTime}</p>
        <p> + {points} points</p>
      </div>
    )
  }

  render() {
    const {
      delayTime,
      src,
      sprite,
      playing,
      bypass,
      muted,
      points
    } = this.props

    return (
      //don't use fragment, having outer div  here is obligatory for css transitions
      <div>
        <HowlerTunaDelay
          delayTime={delayTime}
          src={src}
          sprite={sprite}
          playing={playing}
          bypass={bypass}
          mute={muted}
        />
        <div className={styles.container}>
          {delayTimes.map(time => {
            return (
              <div key={time} className={styles.timebox}>
                <input
                  id={time}
                  name={time}
                  type="checkbox"
                  checked={this.state.answer === time.toString()}
                  onChange={this.onAnswer}
                  value={time}
                />
                <label
                  htmlFor={time}
                  className={points ? styles.right : styles.wrong}
                >
                  {time}
                </label>
              </div>
            )
          })}
          {this.renderBubble()}
        </div>
        <ControlPanel bypassTextOn={'Delay on'} bypassTextOff={'Delay off'} />
        <p className={styles.tip}>
          Tip: for better results switch to a track with more distinct sounds
          e.g. drum loops or one shots.
        </p>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    level: getCurrentLevel(state),
    currentQuestion: getCurrentQuestion(state),
    sprite: getCurrentSprite(state),
    delayTime: getCurrentQuestionValue(state),
    ...state.player,
    ...state.bubble
  }
}

export default connect(mapStateToProps, { answer })(DelayTime)
