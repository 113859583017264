import * as t from 'constants/actionTypes'
import { getQuestions, requestUpdateStats } from '../api'
import { saveToLocalStorage } from '../utils/localStorage'

export const gameStart = gameType => {
  const questions = getQuestions(gameType)

  return {
    type: t.GAME_START,
    payload: {
      questions,
      gameType
    }
  }
}

export const answer = (answerType, position) => {
  return {
    type: t.ANSWER,
    payload: { answerType, position }
  }
}

export const nextQuestion = () => {
  return {
    type: t.NEXT_QUESTION
  }
}

export const countBypass = () => {
  return {
    type: t.COUNT_BYPASS
  }
}

export const levelComplete = () => {
  return {
    type: t.LEVEL_COMPLETE
  }
}

export const nextLevel = () => {
  return {
    type: t.NEXT_LEVEL
  }
}

export const gameOver = () => (dispatch, getState) => {
  dispatch({ type: t.GAME_OVER })
  const state = getState()

  if (state.auth.isLoggedIn) {
    updateStats(dispatch, state)
  }
}

export const gameComplete = () => (dispatch, getState) => {
  dispatch({ type: t.GAME_COMPLETE })
  const state = getState()

  if (state.auth.isLoggedIn) {
    updateStats(dispatch, state)
  }
}

async function updateStats(dispatch, state) {
  const { gameType, score, gameToken } = state.game

  const stats = {
    gameToken,
    score,
    gameType
  }

  try {
    await requestUpdateStats(stats)
  } catch (err) {
    dispatch({
      type: t.ERROR,
      payload: {
        msg: `Sorry, can't save your stats to the server.`
      }
    })
    saveToLocalStorage('stats', stats)
  }
}
