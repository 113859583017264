import * as t from 'constants/actionTypes'

const initialState = {
  play: false,
  slice: ''
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.GAME_START:
      return initialState
    case t.PLAYING_SOUND_END:
      return {
        ...state,
        play: false
      }
    case t.ANSWER:
      return {
        ...state,
        play: true,
        slice: payload.answerType
      }
    case t.LEVEL_COMPLETE:
      return {
        ...state,
        play: true,
        slice: 'LEVEL_COMPLETE'
      }
    case t.NEXT_QUESTION:
      return initialState
    case t.GAME_OVER:
      return {
        ...state,
        play: true,
        slice: 'GAME_OVER'
      }
    case t.GAME_COMPLETE:
      return {
        ...state,
        play: true,
        slice: 'GAME_COMPLETE'
      }
    default:
      return state
  }
}
