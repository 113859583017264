import React, { PureComponent } from 'react'
import styles from './GenrePanel.module.scss'
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

const cx = classNames.bind(styles)

class GenreLink extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    id: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    const { active, disabled, onClick, id } = this.props
    if (!active && !disabled) {
      onClick(id)
    }
  }

  render() {
    const { active, disabled } = this.props

    const classNameButton = cx({
      genre_item: true,
      genre_item_active: active,
      genre_item_disabled: disabled,
    })

    return (
      <>
        <ReactTooltip effect="solid" className={styles.tooltip} />
        <li
          className={classNameButton}
          onClick={this.handleClick}
          data-tip="Please, log in to use this track"
          data-tip-disable={!disabled}
        >
          <span>{this.props.title}</span>
        </li>
      </>
    )
  }
}

const GenrePanel = ({ genres, changeGenre, currentGenre, isLoggedIn }) => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {genres.map((genre) => {
          return (
            <GenreLink
              key={genre.id}
              onClick={changeGenre}
              id={genre.id}
              title={genre.title}
              active={currentGenre === genre.id}
              // disabled={!isLoggedIn && genre.id !== 'default'}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default GenrePanel

GenrePanel.propTypes = {
  changeGenre: PropTypes.func.isRequired,
  currentGenre: PropTypes.string.isRequired,
  genres: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
}
