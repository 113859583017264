import React from 'react'
import { Link } from 'react-router-dom'
import styles from './GameCard.module.scss'
import { isLoggedIn } from 'reducers/auth'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const GameCard = ({
  src,
  name,
  bgSrc,
  desc,
  isLoggedIn,
  published = true,
  forcePublic = true, // set default to false to bring back login func
}) => {
  const isPublic = isLoggedIn || forcePublic

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{name}</h1>
      <img className={styles.background} src={bgSrc} alt={name} />
      {published ? (
        <div className={styles.description_box}>
          <p className={styles.description}>
            {isPublic
              ? desc
              : 'Please log in to unlock all games, get stats and more'}
          </p>
          {isPublic ? (
            <Link to={src} className={styles.link}>
              Play
            </Link>
          ) : (
            <Link to={'/login'} className={styles.link}>
              Login
            </Link>
          )}
        </div>
      ) : (
        <div className={styles.description_box}>
          <p className={styles.soon}>Coming soon</p>
        </div>
      )}
    </div>
  )
}

export default connect((state) => ({ isLoggedIn: isLoggedIn(state) }))(GameCard)

GameCard.propTypes = {
  bgSrc: PropTypes.string,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  forcePublic: PropTypes.bool,
  published: PropTypes.bool,
}
