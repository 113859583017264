import React, { PureComponent } from 'react'
import ControlPanel from 'components/ControlPanel'
import FilterTypeDisplay from './FilterTypeDisplay'
import { connect } from 'react-redux'
import { answer } from 'actions'
import { getCurrentSprite } from 'reducers/sprites'
import {
  getCurrentQuestionValue,
  getCurrentQuestionText
} from 'reducers/questions'

import { getCurrentLevel, getCurrentQuestion } from 'reducers/game'
import HowlerTunaFilterType from './HowlerTunaFilterType'
import PropTypes from 'prop-types'

export class FilterType extends PureComponent {
  static propTypes = {
    gain: PropTypes.number,
    playing: PropTypes.bool.isRequired,
    answer: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    sprite: PropTypes.object,
    bypass: PropTypes.bool.isRequired,
    muted: PropTypes.bool.isRequired,
    points: PropTypes.number,
    currentQuestion: PropTypes.number.isRequired,
    filterData: PropTypes.object,
    filterTypeText: PropTypes.string
  }

  onAnswer = answer => {
    //don't trigger answer if track isn't playing
    if (!this.props.playing) {
      return
    }

    const { filterData: { filterType } = {} } = this.props

    if (answer === filterType) {
      this.props.answer('GOOD')
    } else {
      this.props.answer('FAIL')
    }

    this.props.next()
  }

  render() {
    const {
      filterData,
      src,
      sprite,
      playing,
      bypass,
      muted,
      currentQuestion,
      filterTypeText
    } = this.props

    return (
      <div>
        <HowlerTunaFilterType
          filterData={filterData}
          src={src}
          sprite={sprite}
          playing={playing}
          bypass={bypass}
          mute={muted}
        />
        <FilterTypeDisplay
          onAnswer={this.onAnswer}
          actual={filterTypeText}
          currentQuestion={currentQuestion}
        />
        <ControlPanel bypassTextOn={'Filter on'} bypassTextOff={'Filter off'} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    level: getCurrentLevel(state),
    currentQuestion: getCurrentQuestion(state),
    sprite: getCurrentSprite(state),
    filterData: getCurrentQuestionValue(state),
    filterTypeText: getCurrentQuestionText(state),
    ...state.player
  }
}

export default connect(mapStateToProps, { answer })(FilterType)
