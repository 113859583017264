import { ERROR, CLEAR_ERRORS } from 'constants/actionTypes'

const initialState = {
  show: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ERROR:
      return {
        show: true,
        ...payload
      }
    case CLEAR_ERRORS:
      return initialState
    default:
      return state
  }
}
