import React from 'react'
import Game from 'modules/Game'
import FilterType from 'modules/FilterType'
import * as gameTypes from 'constants/gameTypes'

const GameFilterType = () => {
  return (
    <Game gameType={gameTypes.FILTER_TYPE} withBubble={false}>
      {({ next, points }) => (
        <FilterType next={next} points={points} key={'filter_type'} />
      )}
    </Game>
  )
}

export default GameFilterType
