import React from 'react'
import styles from './Button.module.scss'
import PropTypes from 'prop-types'

function Button({ children, onClick }) {
  return (
    <button onClick={onClick} className={styles.button}>
      {children}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string
}

export default Button
